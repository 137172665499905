import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Event } from 'src/app/entities/events/event.entity';
import { Project } from 'src/app/entities/project.entity';
import { EventsService } from 'src/app/services/api/methods/events.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { BaseSettingComponent } from '../base-setting.component';
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-event-setting',
  templateUrl: './event-setting.component.html',
  styleUrls: ['./event-setting.component.scss']
})
export class EventSettingComponent extends BaseSettingComponent implements OnInit {
  @Input() @Required public project!: Project;

  public $events!: Observable<Event[]>;

  public ngOnInit(): void {
    this.$events = this.eventsService.getAll(this.project.id, 100, 1).pipe(map(res => res.data));
  }

  constructor(
    private eventsService: EventsService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected cdr: ChangeDetectorRef
  ) {
    super(confirmationDialogService, cdr);
  }
}
