import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from 'src/app/services/api/methods/upload.service';
import { Project } from '../../../entities/project.entity';
import { Required } from 'src/app/utils/required-input';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { UnprocessableEntityResponse } from '../../../entities/unprocessable-entity-response';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadComponent {
  @Input() public imagePreviewSrc?: string | null;
  @Input() @Required public project!: Project;
  public imageFile: NgxFileDropEntry | null = null;
  public imageUuid!: string;
  public loading = false;
  @Output() public fileUrl = new EventEmitter<string | null>();

  public removeFile(): void {
    if (!this.imagePreviewSrc) {
      return;
    }
    this.loading = true;
    this.uploadService.deleteFileOnCloudinary(this.project, this.imagePreviewSrc).subscribe(
      () => {
        this.imagePreviewSrc = null;
        this.imageFile = null;
        this.loading = false;
        this.cdr.markForCheck();
        this.fileUrl.emit(null);
      },
      () => {
        this.imagePreviewSrc = null;
        this.imageFile = null;
        this.loading = false;
        this.cdr.markForCheck();
      }
    );
  }

  public dropped(files: NgxFileDropEntry[]): void {
    //TODO: add cleanup if new file is uploaded...
    this.imageFile = files[0];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.type.startsWith('image')) {
            this.loading = true;
            const project_id = this.route.snapshot.data.project ? this.route.snapshot.data.project.id : this.project.id;
            this.uploadService.uploadFilesToUrl('project/' + project_id + '/files/upload', [file]).subscribe(
              response => {
                this.fileUrl.emit(response.files[0]);
                this.imageUuid = response.files[0];
                this.loading = false;
                this.cdr.markForCheck();
                console.log('UPLOAD RESPONSE', response);
              },
              error => {
                if (error instanceof UnprocessableEntityResponse) {
                  this.toastr.error(error.error.data.files[0].errors[0], 'Error');
                } else {
                  this.toastr.error('Error while uploading the image.', 'Error');
                }
                this.imageUuid = '';
                this.fileUrl.emit(null);
                this.loading = false;
                this.cdr.markForCheck();
                console.log('UPLOAD ERROR', error);
              }
            );
            /*this.uploadService.uploadFileToCloudinary(this.project, file).subscribe(
              res => {
                this.imagePreviewSrc = res.secure_url;
                this.fileUrl.emit(res.secure_url);
                this.loading = false;
              },
              err => {
                this.loading = false;
                this.toastr.error('Error while uploading the image.', 'Error');
              }
            );*/
          }
        });
      }
    }
  }

  public getImageSourceUrl(): string | null | undefined {
    const regex = /^\S{25}$/gm;

    if (typeof this.imagePreviewSrc == 'string' && regex.exec(this.imagePreviewSrc)) {
      this.imageUuid = this.imagePreviewSrc;
    }

    if (this.imageUuid) {
      const projectId = this.route.snapshot.data.project ? this.route.snapshot.data.project.id : this.project.id;
      this.cdr.markForCheck();
      return environment.api.endpoint + 'project/' + projectId + '/files/preview/' + this.imageUuid;
    }

    this.cdr.markForCheck();
    return this.imagePreviewSrc;
  }

  constructor(
    private uploadService: UploadService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}
}
