<mat-accordion class="col-12 mb-3">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ setting.name }}
      </mat-panel-title>
      <mat-panel-description> Repeater </mat-panel-description>
    </mat-expansion-panel-header>

    <div (cdkDropListDropped)="drop($event)" *ngIf="data" cdkDropList class="repeater-list">
      <div *ngFor="let repeatedRow of data; let repeatedRowIndex = index" cdkDrag class="repeater-box p-2 rounded shadow-sm mb-2">
        <div class="position-relative px-2">
          <div cdkDragHandle class="drag-handle">
            <svg fill="currentColor" viewBox="0 0 24 24" width="24px">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>

          <ng-container *ngFor="let settingDefinition of setting.fields">
            <app-setting
              (modelChanged)="settingChanged(repeatedRowIndex, settingDefinition.name, $event)"
              [model]="data[repeatedRowIndex][settingDefinition.name]"
              [project]="project"
              [templateSetting]="settingDefinition"
              [twoColumns]="false"
              [useRepeater]="false"
              ngDefaultControl
            >
            </app-setting>
          </ng-container>

          <button (click)="removeRow(repeatedRowIndex)" class="btn-delete" color="warn" mat-flat-button>
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <button (click)="addRepeaterRow()" class="mr-2 mb-2" color="accent" mat-flat-button>Add Row</button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
