import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Required } from 'src/app/utils/required-input';
import { AvailableLanguagesEntity } from '../../../../entities/enums/available-languages.entity';
import { ConfirmationDialogService } from '../../../../services/confirmation-dialog.service';
import { LanguageList } from '../../../../utils/language-list';
import { MetaData } from '../../../../entities/meta-data.entity';

@Component({
  selector: 'app-edit-locale-selector',
  templateUrl: './edit-locale-selector.component.html'
})
export class EditLocaleSelectorComponent {
  @Input() @Required public locale!: AvailableLanguagesEntity;
  @Input() @Required public meta!: MetaData;
  @Input() public hideTranslationStatus: boolean = false;
  @Input() public fullWidth: boolean = false;
  @Input() public disabled: boolean = false;

  @Output() public readonly localeChange = new EventEmitter<AvailableLanguagesEntity>();
  public originalLocale!: AvailableLanguagesEntity;

  public localeChanged(formLocale): void {
    if (formLocale !== this.locale) {
      if (!this.hideTranslationStatus) {
        this.confirmationDialogService.confirm('Language change', 'This will reload the page without saving changes?').subscribe(res => {
          if (!res) {
            this.locale = this.originalLocale;
            return;
          } else {
            this.localeChange.emit(formLocale);
            this.originalLocale = formLocale;
          }
        });
      } else {
        this.localeChange.emit(formLocale);
        this.originalLocale = formLocale;
      }
    }
  }

  public getLanguageName(code: string): string {
    return LanguageList.languageName(code);
  }

  public ngOnInit(): void {
    this.originalLocale = this.locale;
  }

  constructor(private confirmationDialogService: ConfirmationDialogService) {}
}
