import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { SalesInsightsTimeframesEntity } from '../../../entities/sales/sales-insights-timeframes.entity';
import { SalesInsights } from '../../../entities/sales/sales-insights.entity';
import { SingleResponse } from '../../../entities/single-response.entity';
import { SalesTimelineItem } from '../../../entities/sales/sales-timeline-item.entity';
import { SalesCampaignGuest } from '../../../entities/sales/sales-campaign-guest.entity';
import { take } from 'rxjs/operators';
import { PaginatedResponse } from '../../../entities/paginated-response.entity';

@Injectable({
  providedIn: 'root'
})
export class SalesDashboardService extends ApiService {
  private endpoint = 'sales';

  public getInsights(projectId: number, timeframe: SalesInsightsTimeframesEntity): Observable<SingleResponse<SalesInsights>> {
    return this.http
      .get<SingleResponse<SalesInsights>>(`projects/${projectId}/${this.endpoint}/insights`, {
        params: {
          timeframe: timeframe
        }
      })
      .pipe(take(1));
  }

  public getTimeline(projectId: number): Observable<PaginatedResponse<SalesTimelineItem>> {
    return this.http.get<PaginatedResponse<SalesTimelineItem>>(`projects/${projectId}/${this.endpoint}/timeline`).pipe(take(1));
  }

  public getCampaignGuestCounts(projectId: number): Observable<SingleResponse<SalesCampaignGuest>> {
    return this.http.get<SingleResponse<SalesCampaignGuest>>(`projects/${projectId}/${this.endpoint}/campaign-guests`).pipe(take(1));
  }
}
