<div class="col-12 mb-1">
  <label [for]="name" [innerText]="name"></label>

  <button (click)="switchToDefault()" *ngIf="ngModel !== null && hasDefaultValue" mat-button type="button">Use Default</button>

  <button (click)="update('')" *ngIf="ngModel === null" mat-button type="button"><i class="fa fa-edit"></i></button>
</div>

<div [class.col-12]="fullWidth" [class.col-sm-10.col-md-6]="!fullWidth">
  <app-upload
    (fileUrl)="ngModel = $event; update($event)"
    *ngIf="ngModel !== null"
    [imagePreviewSrc]="ngModel"
    [project]="project"
    class="upload"
  ></app-upload>

  <img *ngIf="ngModel === null" [src]="defaultValue ? defaultValue : 'https://via.placeholder.com/500'" alt="" class="img-fluid" />
</div>
