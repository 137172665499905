import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/api/methods/authentication.service';

@Injectable()
export class IsLoggedIn implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  public canActivate() {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { redirect: window.location.pathname } });
      return false;
    }
  }
}
