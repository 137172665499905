import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseSettingComponent } from '../base-setting.component';
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-html-setting',
  templateUrl: './html-setting.component.html',
  styleUrls: ['./html-setting.component.scss']
})
export class HtmlSettingComponent extends BaseSettingComponent {
  @Input() @Required public defaultValue!: string;
  public html!: string;

  @Input() public twoColumns = true;
  @Input() @Required public name!: string;
  @Output() public updated: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  @Required
  public set ngModel(val: string) {
    this.html = val;
  }

  // Workaround since quill outputs null and not an empty string when empty, which causes the component to go into default mode in an instant
  public switchToDefault(): void {
    this.confirmationDialogService
      .confirm('Use Default', 'Are you sure you want to use the default value of this field? This will delete the current value.')
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.update(null, true);
        this.cdr.markForCheck();
      });
  }

  public update(value, overrideCheck?: boolean): void {
    if (!overrideCheck && value === null) {
      this.html = '';
      this.updated.emit('');
    } else {
      // IDK why this editor doesn't escape nbsp but w/e
      // Probably needs better escaping in the future
      this.html = (value as string).replace(/\&nbsp;/g, ' ');
      this.updated.emit(this.html);
    }
  }
}
