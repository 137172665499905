import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpCacheService } from './http-cache.service';

@Injectable()
export abstract class ApiService {
  public static parsePageConfig(pageConfig: { page: number; per_page: number; sort_by: string; sort_dir: 'asc' | 'desc'; search: string }) {
    return {
      page: pageConfig.page.toString(),
      per_page: pageConfig.per_page.toString(),
      sort_by: pageConfig.sort_by.toString(),
      sort_dir: pageConfig.sort_dir.toString(),
      search: pageConfig.search.toString()
    };
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T | undefined> => {
      // if (error.status === 401) {
      //   this.router.navigate(['/login']);
      // }
      if (error.status !== 404 && error.status !== 401) {
        this.error(`${operation} failed: ${error.message}`);
      }

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  protected log(message: string): void {
    if (!environment.production) {
      console.log('Api Service: ' + message);
    }
  }

  protected error(message: string): void {
    if (!environment.production) {
      console.error('Api Service Error:' + message);
    }
  }
  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected httpCache: HttpCacheService,
    protected storage: LocalStorageService
  ) {}
}
