<div class="mat-calendar-footer px-3 pb-3">
  <div class="d-flex justify-content-between">
    <mat-form-field appearance="outline" class="w-50 pr-1">
      <mat-label>Start</mat-label>
      <input
        (change)="beginTimeChange($event)"
        [disabled]="!calendar.beginDate"
        [value]="beginTimeString"
        id="start"
        matInput
        name="start"
        placeholder="Start"
        required
        type="time"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-50 pl-1">
      <mat-label>End</mat-label>
      <input
        (change)="endTimeChange($event)"
        [disabled]="!calendar.endDate"
        [value]="endTimeString"
        id="end"
        matInput
        name="end"
        placeholder="End"
        required
        type="time"
      />
    </mat-form-field>
  </div>

  <div class="d-flex justify-content-end">
    <button (click)="doneClicked()" color="primary" mat-raised-button>Done</button>
  </div>
</div>
