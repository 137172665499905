import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {
  private subject = new BehaviorSubject<string>('');
  private lastURL = '';

  constructor(private router: Router) {
    // clear title on route change
    router.events.subscribe(event => {
      // @ts-ignore
      if (event instanceof NavigationStart && event.url.split('?')[0] !== this.lastURL) {
        this.lastURL = event.url.split('?')[0];
        this.subject.next('');
      }
    });
  }

  public getTitle(): Observable<string> {
    return this.subject.asObservable();
  }

  public setTitle(title: string) {
    this.subject.next(title);
  }
}
