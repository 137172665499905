/* tslint-disable */
import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

/**
 * This class is used for development and replaces the real API
 */
@Injectable({
  providedIn: 'root'
})
export class InMemoryDataService implements InMemoryDbService {
  public createDb() {
    return {};
  }
}
