import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, of, throwError, timer } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { User, UserRole } from '../../../entities/user.entity';
import { ApiService } from '../api.service';
import { HttpCacheService } from '../http-cache.service';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends ApiService {
  public tokenIsRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private endpoint = 'auth';
  private $meObservable?: Observable<User>;

  public login(email: string, password: string): Observable<User> {
    const url = this.endpoint + '/login';

    this.clearStorage();
    this.httpCache.deleteCache();
    return this.http
      .post<{ access_token: string }>(url, { email, password })
      .pipe(
        map(res => {
          this.storeToken(res.access_token);
        }),
        switchMap(res => this.getMe()),
        map(user => {
          this.storage.store('user', user);

          if (this.storage.retrieve('last_user_id') !== user.id) {
            this.storage.clear('last_team_id');
            this.storage.store('last_user_id', user.id);
          }

          this.httpCache.deleteCache();
          return user;
        })
      );
  }

  public getToken(): string {
    return this.storage.retrieve('access_token');
  }

  public refreshToken(): Observable<boolean> {
    const url = this.endpoint + '/refresh';
    this.tokenIsRefreshing.next(true);
    return this.http.post<{ access_token: string }>(url, {}).pipe(
      map((refreshResponse: { access_token: string }) => {
        // store  jwt token in local storage to keep user logged in between page refreshes
        this.storeToken(refreshResponse.access_token);
        this.tokenIsRefreshing.next(false);

        // add token to storage
        return true; // return the new authorization token
      }),
      catchError(error => {
        if (error.status === 401) {
          // logout users, redirect to login page
          this.logout();
          this.toast.error('Your session has expired');
          this.router.navigateByUrl('/login');
          return of(false);
        }
        this.tokenIsRefreshing.next(false);
        return of(false);
      })
    );
  }

  public refreshTokenIfNeeded(): void {
    if (this.jwtHelperService.isTokenExpired(this.getToken(), 5 * 60)) {
      console.log('need to refreshToken');
      this.refreshToken().subscribe(() => {
        console.log('token refreshed');
        this.toast.clear(this.storage.retrieve('auth_toast_id'));
        this.storage.clear('auth_toast_id');
      });
    } else {
      console.log('token is valid');
    }
  }

  public sendPasswordResetMail(email: string): Observable<{ message: string }> {
    const url = this.endpoint + '/send-reset-mail';
    this.clearStorage();
    return this.http.post<{ message: string }>(url, { email });
  }

  public resetPassword(email: string, password: string, password_confirmation: string, token: string): Observable<User> {
    const url = this.endpoint + '/reset';
    this.clearStorage();
    this.httpCache.deleteCache();
    return this.http
      .post<{ access_token: string }>(url, {
        email,
        password,
        password_confirmation,
        token
      })
      .pipe(
        map((res: { access_token: string }) => {
          this.storeToken(res.access_token);
        }),
        switchMap(() => this.getMe()),
        map(user => {
          this.storage.store('user', user);
          this.httpCache.deleteCache();
          return user;
        })
      );
  }

  public isLoggedIn(): boolean {
    if (!this.storage.retrieve('access_token')) {
      return false;
    }
    return moment(this.jwtHelperService.getTokenExpirationDate(this.getToken())).isAfter(moment());
  }

  public getMe(): Observable<User> {
    if (this.$meObservable) {
      return this.$meObservable;
    }
    const url = 'me';
    this.$meObservable = this.http.get<{ data: User }>(url).pipe(
      map(res => res.data),
      shareReplay(),
      tap(user => {
        this.storage.store('user', user);
        this.httpCache.deleteCache();
        Bugsnag.setUser(`${user.id}`, user.email, `${user.first_name} ${user.last_name}`);
      })
    );

    return this.$meObservable;
  }

  public updateMe(user: User): Observable<User> {
    const url = 'me';
    this.$meObservable = this.http.put<User>(url, user).pipe(shareReplay());
    return this.$meObservable;
  }

  public getUserType(): UserRole | undefined {
    const user: User = this.storage.retrieve('user');
    if (!user) {
      return undefined;
    }
    return user.is_admin ? UserRole.Admin : UserRole.User;
  }

  public logout(): Observable<void> {
    const url = this.endpoint + '/logout';
    return this.http.post<undefined>(url, {}).pipe(
      map(response => {
        this.clearStorage();
        this.httpCache.deleteCache();
        return response;
      })
    );
  }

  public signup({
    email,
    password,
    password_confirmation,
    firstName = '',
    lastName = '',
    city = 'nll',
    company = '',
    phone = '',
    invite_code = ''
  }: {
    email: string;
    password: string;
    password_confirmation: string;
    firstName?: string;
    lastName?: string;
    city?: string;
    company?: string;
    phone?: string;
    invite_code?: string;
  }): Observable<null | User> {
    const url = 'auth/register';
    this.httpCache.deleteCache();

    return this.http
      .post<{ message: 'created'; access_token: string; expires_in: string; token_type: 'bearer' }>(url, {
        invite_code,
        email,
        password,
        password_confirmation,
        first_name: firstName,
        last_name: lastName,
        city,
        company,
        phone
      })
      .pipe(
        switchMap((res: { message: 'created'; access_token?: string; expires_in?: string; token_type?: 'bearer' }) => {
          if (res.access_token) {
            this.storeToken(res.access_token);
            return this.getMe();
          }
          return of(null);
        })
      );
  }

  public resendVerificationMail(email: string): Observable<any> {
    const url = 'auth/resend-verification-mail';
    return this.http
      .post<any>(url, { email })
      .pipe(
        map((response: User) => {
          return response;
        })
      );
  }

  private storeToken(access_token: string): void {
    this.storage.store('access_token', access_token);
    // refresh the token in time
    this.refreshTokenInTime();
  }

  private refreshTokenInTime() {
    if (!this.getToken()) {
      return;
    }
    const expiryDate = moment(this.jwtHelperService.getTokenExpirationDate());
    // Causes Refresh request spamming when the token TTL is less then 3 min
    const refreshTokenInMs = expiryDate.diff(moment(), 'milliseconds', true) - 3 * 60 * 1000;

    timer(refreshTokenInMs).subscribe(time => {
      if (this.isLoggedIn()) {
        this.storage.store(
          'auth_toast_id',
          this.toast.info('Try to refresh auth token... will fade out if successful', '', {
            disableTimeOut: true
          }).toastId
        );

        this.refreshTokenIfNeeded();
      } else {
        this.toast.error('Your session is expired - Please copy and paste your work away and login again!', '', {
          disableTimeOut: true
        });
      }
    });
  }

  private clearStorage() {
    this.httpCache.deleteCache();
    this.$meObservable = undefined;
    this.storage.clear('access_token');
    this.storage.clear('refresh_token');
    this.storage.clear('user');
  }

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected toast: ToastrService,
    protected httpCache: HttpCacheService,
    protected storage: LocalStorageService,
    protected jwtHelperService: JwtHelperService
  ) {
    super(http, router, httpCache, storage);
    this.httpCache.cacheDeleted.subscribe(() => (this.$meObservable = undefined));
    this.refreshTokenInTime();
  }
}
