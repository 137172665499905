<app-search-input (searchValue)="onScroll(0, $event)"></app-search-input>

<div #table>
  <ngx-datatable
    (activate)="onActivate($event)"
    (scroll)="onScroll($event.offsetY)"
    [columnMode]="'force'"
    [headerHeight]="headerHeight"
    [loadingIndicator]="isLoading"
    [rowHeight]="rowHeight"
    [rows]="rows"
    [scrollbarV]="true"
    [selectionType]="'checkbox'"
    class="material audiences-datatable"
  >
    <ngx-datatable-column [canAutoResize]="false" [draggable]="false" [resizeable]="false" [sortable]="false" [width]="30">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <mat-checkbox
          [checked]="row.checkedStatus === CheckedStatus.checked || row.checkedStatus === CheckedStatus.indeterminate"
          [indeterminate]="row.checkedStatus === CheckedStatus.indeterminate"
        ></mat-checkbox>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Title" prop="audience.title"></ngx-datatable-column>
    <ngx-datatable-column name="Created at" prop="audience.created_at">
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value | date: 'short' }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
