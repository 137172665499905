import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFindByUuid'
})
export class ArrayFindByUuidPipe implements PipeTransform {
  public transform(value: Array<any>, args?: string): any | null {
    return value?.find(e => e?.uuid === args);
  }
}
