<div class="w-100 d-flex align-items-center">
  <mat-form-field appearance="outline" class="flex-grow-1">
    <mat-label>{{ name }}</mat-label>
    <mat-select (ngModelChange)="update($event)" *ngIf="ngModel !== null; else defaultInput" [id]="name" [name]="name" [ngModel]="ngModel">
      <mat-option *ngFor="let item of options" [value]="item">{{ item }}</mat-option>
    </mat-select>

    <ng-template #defaultInput>
      <mat-select [id]="name" [name]="name" [ngModel]="defaultValue" disabled>
        <mat-option *ngFor="let item of options" [value]="item">{{ item }}</mat-option>
      </mat-select>
    </ng-template>

    <button (click)="update(options[0])" *ngIf="ngModel === null" class="suffix-button" mat-button matSuffix type="button">
      <i class="fa fa-edit"></i>
    </button>
  </mat-form-field>

  <button (click)="switchToDefault()" *ngIf="ngModel !== null && hasDefaultValue" class="suffix-button" mat-button matSuffix type="button">
    Use Default
  </button>
</div>
