import { Injectable } from '@angular/core';
import { NgxCheatsheetComponent, NgxHotkeysService } from '@balticcode/ngx-hotkeys';

@Injectable({
  providedIn: 'root' // needs to be a singleton in order to work
})
export class CheatSheetService {
  private cheatSheetComponent!: NgxCheatsheetComponent;

  public init(cheatSheetComponent: NgxCheatsheetComponent): void {
    this.cheatSheetComponent = cheatSheetComponent;
  }

  public toggleCheatSheet(): void {
    if (!this.cheatSheetComponent) {
      return;
    }
    this.cheatSheetComponent.hotkeys = this.hotkeyService.hotkeys;
    this.cheatSheetComponent.toggleCheatSheet();
  }
  constructor(private hotkeyService: NgxHotkeysService) {}
}
