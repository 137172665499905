import { Component, Input } from '@angular/core';
import { Required } from '../../../utils/required-input';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html'
})
export class FormControlErrorComponent {
  // never rename this one to formControl
  @Input() @Required public control!: FormControl;
}
