<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ name }}</mat-label>
  <input
    (ngModelChange)="update($event)"
    *ngIf="ngModel !== null"
    [id]="name"
    [name]="name"
    [ngModel]="ngModel"
    [placeholder]="name"
    matInput
    type="text"
  />

  <input *ngIf="ngModel === null" [placeholder]="name" [value]="defaultValue" disabled matInput type="text" />

  <button (click)="switchToDefault()" *ngIf="ngModel !== null && hasDefaultValue" class="suffix-button" mat-button matSuffix type="button">
    Use Default
  </button>

  <button (click)="update('')" *ngIf="ngModel === null" class="suffix-button" mat-button matSuffix type="button">
    <i class="fa fa-edit"></i>
  </button>
</mat-form-field>
