import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getmap'
})
export class GetMapPipe implements PipeTransform {
  public transform(value: Map<any, any>, args?: any): any {
    return value.get(args);
  }
}
