export enum ActionType {
  booking = 'booking',
  formSubmission = 'form_submission',
  createMeetingExternal = 'create_meeting_external',
  checkin = 'checkin',
  login = 'login',
  logout = 'logout',
  registration = 'registration',
  changeLanguage = 'changeLanguage'
}
