import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {
  public transform(value: Record<string, any>, args?: any): string[] {
    return Object.keys(value);
  }
}
