import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ColumnData } from '../../../../entities/column-data.entity';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { ConfirmationDialogService } from '../../../../services/confirmation-dialog.service';
import { Favorite, FavoriteService } from '../../../../services/favorite.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface TableSettingsDialogData {
  columns: ColumnDataSelection[];
  filteredColumns?: ColumnDataSelection[];
  favoriteKey?: string;
}

export interface ColumnDataSelection extends ColumnData {
  selected?: boolean;
}

@Component({
  selector: 'app-table-column-settings',
  templateUrl: './table-column-settings.component.html',
  styleUrls: ['./table-column-settings.component.scss']
})
export class TableColumnSettingsComponent {
  @ViewChild('columnList') public columnList!: MatSelectionList;

  public columns: ColumnDataSelection[] = [];
  public filteredColumns: ColumnDataSelection[] = [];
  public favorites: Favorite[] = [];
  public selectedFavorite: Favorite;

  favoriteKey = '';
  newFavoriteName = '';

  saveFavorite() {
    this.filteredColumns = this.columnList.selectedOptions.selected.map((selected: MatListOption) => selected.value);

    this.selectedFavorite = {
      id: Date.now(),
      label: this.newFavoriteName,
      active: true,
      data: this.filteredColumns
    };

    this.favorites = this.favoriteService.addFavorite(this.favoriteKey, this.selectedFavorite, true);

    this.newFavoriteName = '';
  }

  removeFavorite(index: number) {
    this.confirmationDialogService.confirm('Delete', 'Delete column favorite?').subscribe(res => {
      if (!res) {
        return;
      }
      this.favoriteService.removeFavorite(this.favoriteKey, index);
      this.favorites.splice(index, 1);
      this.selectFavorite(this.favorites[0]);
    });
  }

  selectFavorite(favorite: Favorite) {
    if (!favorite) {
      return;
    }
    this.filteredColumns = favorite.data as ColumnDataSelection[];
    this.columns = this.columns.map(column => {
      const isSelected = this.filteredColumns ? !!this.filteredColumns.find(filteredColumn => filteredColumn.name == column.name) : false;
      return { ...column, selected: isSelected };
    });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    if (self.filteredColumns) {
      this.columns
        .sort(function (a, b) {
          const matchA = self.filteredColumns.map(column => column.name).indexOf(a.name);
          const matchB = self.filteredColumns.map(column => column.name).indexOf(b.name);
          return matchA - matchB;
        })
        .sort(function (a, b) {
          return a.selected && b.selected ? 1 : -1;
        });
    }

    this.selectedFavorite = { ...favorite, active: true };
    this.favorites = this.favoriteService.changeFavorite(this.favoriteKey, this.selectedFavorite);
  }

  selectListItem(): void {
    this.filteredColumns = this.columnList.selectedOptions.selected.map((selected: MatListOption) => selected.value);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.filteredColumns.sort(function (a, b) {
      return self.columns.indexOf(a) - self.columns.indexOf(b);
    });

    if (this.selectedFavorite) {
      this.selectedFavorite.data = this.filteredColumns;
    }
  }

  dropListItem(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.selectListItem();
  }

  appendFavorite(): void {
    if (this.selectedFavorite) {
      this.favoriteService.changeFavorite(this.favoriteKey, this.selectedFavorite);
    }
    this.dialogRef.close(this.filteredColumns);
  }

  constructor(
    public dialogRef: MatDialogRef<TableColumnSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TableSettingsDialogData,
    private toastr: ToastrService,
    private favoriteService: FavoriteService,
    protected confirmationDialogService: ConfirmationDialogService
  ) {
    this.favoriteKey = data.favoriteKey || 'columns';
    this.filteredColumns = data.filteredColumns || [];

    this.columns = data.columns.map(column => {
      const isSelected = !!this.filteredColumns.find(filteredColumn => filteredColumn.name == column.name);
      return { ...column, selected: isSelected };
    });

    this.favorites = this.favoriteService.getFavorites(this.favoriteKey);

    const activeFavorite = this.favorites.find(favorite => favorite.active);

    this.selectedFavorite = activeFavorite!;

    if (activeFavorite) {
      this.selectFavorite(activeFavorite);
    }
  }
}
