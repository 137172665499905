import { Moment } from 'moment';
import { Attachment, LegacyAttachment } from '../attachments.entity';
import { Deserializable } from '../deserializable.model';
import { BookingDetails, MultiValueBookingData } from '../events/booking-details.entity';
import { Model } from '../model';
import { FormFieldGroup } from './form-field-group.entity';

export class FormSubmission extends Model implements Deserializable<FormSubmission> {
  public id?: number;
  public project_id!: number;
  public form_id!: number;
  public form_fields_when_saved: FormFieldGroup[] = [];
  public data!: BookingDetails;
  public updated_at!: Moment;
  // public created_at!: Moment; // not part of api response any more
  public attachments: Attachment[] | LegacyAttachment[] = [];
  protected _dateAttributes = ['updated_at'];

  public export(keys?: string[]): { [key: string]: any } {
    let flatBookingDetails = {};
    if (this.data) {
      flatBookingDetails = this.submissionData(this.data);
    }
    const result = this.reduceObjectToKeys({ ...this, ...flatBookingDetails }, keys);
    console.log(result);
    return result;
  }

  /** Iterates through booking details and returns a simple key value pairs */
  private submissionData(data: BookingDetails): { [key: string]: string } {
    const flatBookingDetails = {};
    Object.entries(data).forEach(([key, values]) => {
      if (key === 'files') {
        return;
      }
      if (Array.isArray(values.data) && values.data.every(e => typeof e !== 'string')) {
        flatBookingDetails[key] = (values.data as MultiValueBookingData[]).map((e: MultiValueBookingData) => e.value);
      } else {
        flatBookingDetails[key] = values.data;
      }
    });
    return flatBookingDetails;
  }

  /** Filters object to specified keys */
  private reduceObjectToKeys(object: { [key: string]: any }, keys: string[] = []): { [key: string]: any } {
    if (keys.length === 0) {
      return object;
    }
    return Object.keys(object)
      .filter(key => keys.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: object[key]
        };
      }, {});
  }
}
