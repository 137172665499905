// tslint:disable-next-line:no-duplicate-imports
import * as moment from 'moment';
import { Moment } from 'moment';
import { ActionType } from './action-type.enum';
import { Emailing } from './emailing.entity';
import { FormAction } from './form-action.model';

export enum BOOKING_ACTION_SAVE_STATES {
  saved = 'saved',
  error = 'error',
  init = 'init'
};

export class BookingAction extends FormAction {
  public type = ActionType.booking;
  public is_default?: boolean = false;

  public payload: {
    max_guests: number | null;
    title: string | null;
    booking_date_start: Moment | null;
    booking_date_end: Moment | null;
    form_id: number | null;
    form: { title: string } | null;
    audience_id: number | null;
    audience?: { title: string } | null;
    emails: Emailing[];
    enforce_booking_source: boolean | false;
    booking_sources:  string[] | [];
    enforce_admin_verification: boolean;
    pending_audience_id?: string;
    express_booking: boolean;
    slots_per_row: number;
    organizer_email?: string;
  } = {
    max_guests: null,
    title: null,
    booking_date_start: moment().startOf('hour'),
    booking_date_end: moment().startOf('hour').add(45, 'minutes'),
    form_id: null,
    form: null,
    audience_id: null,
    audience: null,
    emails: [],
    enforce_booking_source: false,
    booking_sources: [],
    enforce_admin_verification: false,
    express_booking: false,
    slots_per_row: 3,
    organizer_email: ''
  };

  public serialize(): any {
    return {
      ...super.serialize(),
      payload: {
        ...this.payload,
        booking_date_start: this.momentToString(this.payload.booking_date_start),
        booking_date_end: this.momentToString(this.payload.booking_date_end)
      }
    };
  }

  public deserialize(input: any): this {
    super.deserialize(input);
    this.payload.booking_date_start = this.stringToMoment(input.payload.booking_date_start);
    this.payload.booking_date_end = this.stringToMoment(input.payload.booking_date_end);

    if (!input.payload.emails) {
      this.payload.emails = [];
    }
    return this;
  }

  constructor(booking_date_start?: Moment, booking_date_end?: Moment) {
    super();
    if (booking_date_start) {
      this.payload.booking_date_start = booking_date_start;
    }
    if (booking_date_end) {
      this.payload.booking_date_end = booking_date_end;
    }
  }
}
