export enum NotificationType {
  FORM_SUBMISSION_CREATED = 'form_submission.created',
  FORM_SUBMISSION_UPDATED = 'form_submission.updated',
  FORM_SUBMISSION_DELETED = 'form_submission.deleted',

  GUEST_CREATED = 'guest.created',
  GUEST_UPDATED = 'guest.updated',
  GUEST_DELETED = 'guest.deleted',

  EVENT_BOOKING_CREATED = 'event_booking.created',
  EVENT_BOOKING_UPDATED = 'event_booking.deleted',
  EVENT_BOOKING_DELETED = 'event_booking.deleted'
}
