import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { emmetCSS, emmetHTML } from 'emmet-monaco-es';
import * as monaco from 'monaco-editor';
import { editor, IPosition, KeyCode, KeyMod, languages } from 'monaco-editor';
import { TemplateLanguage } from 'src/app/entities/pages/theme/template-language.enum';
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import IEditorConstructionOptions = editor.IEditorConstructionOptions;
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  @Output() public save: EventEmitter<void> = new EventEmitter<void>();
  @Output() public contentChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public cursorPosition: EventEmitter<editor.ICursorPositionChangedEvent> = new EventEmitter<
    editor.ICursorPositionChangedEvent
  >();
  @Input() public setPosition: EventEmitter<IPosition> = new EventEmitter<IPosition>();
  @ViewChild('editorContainer') public editorContainer!: ViewChild;
  private _lang!: TemplateLanguage;
  private contentValue!: string;
  private initPosition: IPosition | null = null;
  private completionItemsValue: string[] = [];
  private templateSettingsCompletionProvider: languages.CompletionItemProvider = {
    provideCompletionItems: (model, position) => ({ suggestions: [] })
  };
  private editor!: IStandaloneCodeEditor;
  private constuctionOptions: editor.IEditorConstructionOptions = {
    scrollBeyondLastLine: false
  };

  @Input()
  @Required
  public set language(lang: TemplateLanguage) {
    this._lang = lang;
    if (this.editor) {
      this.editor.setModel(editor.createModel(this.contentValue, lang));
    }
  }
  public get language(): TemplateLanguage {
    return this._lang;
  }

  @Input()
  @Required
  public set content(val: string) {
    if (val === this.contentValue) {
      return;
    }
    this.contentValue = val;
    this.contentChange.emit(this.contentValue);
    if (this.editor) {
      this.editor.setModel(editor.createModel(this.contentValue, this._lang));
    }
  }
  public get content(): string {
    return this.contentValue;
  }

  @Input()
  public set completionItems(val: string[]) {
    this.completionItemsValue = val;

    this.registerCompletionItems(val);
  }
  public get completionItems(): string[] {
    return this.completionItemsValue;
  }

  @HostListener('window:resize')
  public onResize(): void {
    if (this.editor) {
      this.editor.layout();
    }
  }

  public ngOnInit(): void {
    this.initMonaco();
  }

  protected initMonaco(): void {
    if (this.editor) {
      console.log('already inited');
      return;
    }

    const options: IEditorConstructionOptions = { ...this.constuctionOptions, ...{ language: this.language, automaticLayout: false } };

    const el = document.getElementById('editorContainer') as HTMLElement;

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    self.MonacoEnvironment = {
      getWorkerUrl(moduleId, label) {
        //todo: check if and why these workers are needed? currently this config is causing problems...
        if (label === 'json') {
          // return './json.worker.bundle.js';
        }
        if (label === 'css') {
          // return './css.worker.bundle.js';
        }
        if (label === 'html') {
          // return './html.worker.bundle.js';
        }
        if (label === 'typescript' || label === 'javascript') {
          // return './ts.worker.bundle.js';
        }
        return './assets/monaco/vs/base/worker/workerMain.js';
      }
    };
    emmetCSS(monaco);
    emmetHTML(monaco);
    this.editor = editor.create(el, options);

    if (this.editor == null) {
      alert('monaco init failed');
    }

    if (this.contentValue) {
      this.editor.setValue(this.contentValue);
    } else {
      console.log('content undefined!');
    }

    this.editor.onDidChangeModelContent((e: any) => {
      this.contentValue = this.editor.getValue();

      this.contentChange.emit(this.contentValue);
      // value is not propagated to parent when executing outside zone.
      // this.zone.run(() => this._value = value);
    });

    this.editor.focus();

    if (this.initPosition !== null) {
      this.editor.setPosition(this.initPosition);
      this.editor.revealPositionInCenterIfOutsideViewport(this.initPosition, editor.ScrollType.Immediate);
    }

    this.registerCompletionItems(this.completionItems);
    monaco.languages.registerCompletionItemProvider('html', this.templateSettingsCompletionProvider);

    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      allowUnusedLabels: true
    });

    // tslint:disable-next-line:no-bitwise
    this.editor.addCommand(KeyMod.CtrlCmd | KeyCode.KEY_S, e => {
      this.save.emit();
    });

    editor.defineTheme('vs-dark-custom', {
      base: 'vs-dark',
      inherit: true,
      colors: {},
      rules: []
    });

    editor.setTheme('vs-dark-custom');
    this.editor.layout();
  }

  private registerCompletionItems(items: string[]): void {
    // @ts-ignore
    this.templateSettingsCompletionProvider.provideCompletionItems = (model, position) => {
      const complItems = {
        suggestions: items.map(item => ({
          label: item,
          kind: languages.CompletionItemKind.Value,
          detail: 'A template setting',
          insertText: item,
          range: null
        }))
      };
      complItems.suggestions.push(
        {
          label: 'style_tag',
          kind: languages.CompletionItemKind.Function,
          detail: 'Renders  a <link rel="stylesheet" ...> tag',
          insertText: "style_tag('')",
          range: null
        },
        {
          label: 'resize_asset',
          kind: languages.CompletionItemKind.Function,
          detail: 'Renders  a Cloudinary image URL',
          insertText: 'resize_asset(image, {width:  100, height: 100}) ',
          range: null
        },
        {
          label: 'style_tag',
          kind: languages.CompletionItemKind.Function,
          detail: 'Renders  a <script href=".."></script> tag',
          insertText: "style_tag('')",
          range: null
        }
      );

      return complItems;
    };
  }
}
