import { Moment } from 'moment';
import { Deserializable } from '../deserializable.model';
import { Model } from '../model';
import { NotificationType } from './notification-type.enum';

export class Notification extends Model implements Deserializable<Notification> {
  public readonly id?: string;
  public readonly project_id!: number;
  public readonly created_at?: Moment;
  public readonly updated_at?: Moment;
  public readonly type!: NotificationType;
  public readonly read_at?: Moment;
  public readonly data!: {};
  public readonly title!: string;
  public readonly message!: string;
  protected _dateAttributes = ['created_at', 'updated_at', 'read_at'];
}
