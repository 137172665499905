<div class="mat-quill-input-container" [formGroup]="parts">
  <quill-editor
    #content
    (onContentChanged)="_handleInput($event)"
    [modules]="modules"
    bounds="self"
    formControlName="content"
    format="html"
    sanitize="false"
    theme="snow"
    [placeholder]="placeholder"
    [preserveWhitespace]="pageBuilderMode"
    [classes]="classes"
  ></quill-editor>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</div>
