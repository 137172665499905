<span [ngSwitch]="type">
  <span *ngSwitchCase="TemplateSettingType.select">
    <i class="far fa-hand-pointer"></i>
  </span>
  <span *ngSwitchCase="TemplateSettingType.text">
    <i class="fas fa-align-left"></i>
  </span>

  <span *ngSwitchCase="TemplateSettingType.image">
    <i class="fas fa-image"></i>
  </span>

  <span *ngSwitchCase="TemplateSettingType.boolean">
    <i class="fas fa-toggle-on"></i>
  </span>

  <span *ngSwitchCase="TemplateSettingType.textarea">
    <i class="fas fa-paragraph"></i>
  </span>

  <span *ngSwitchCase="TemplateSettingType.repeater">
    <i class="fas fa-list"></i>
  </span>

  <span *ngSwitchCase="TemplateSettingType.html">
    <i class="fas fa-code"></i>
  </span>

  <span *ngSwitchCase="TemplateSettingType.color">
    <i class="fas fa-palette"></i>
  </span>

  <span *ngSwitchCase="TemplateSettingType.event">
    <i class="fas fa-calendar-day"></i>
  </span>

  <span *ngSwitchCase="TemplateSettingType.event_list">
    <i class="fas fa-calendar-alt"></i>
  </span>

  <span *ngSwitchDefault> <i class="fas fa-star"></i> </span>
</span>
