import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { Redirect } from 'src/app/entities/project-settings/redirect.entity';
import { TeamMemberEntity } from '../../../entities/team/team-member.entity';
import { TeamEntity } from '../../../entities/team/team.entity';
import { ApiService } from '../api.service';
import { TeamInvitationsComponent } from '../../../components/team/team-tabs/access/team-invitations/team-invitations.component';
import { TeamInvitationEntity } from '../../../entities/team/team-invitation.entity';
import { BillingAddress } from '../../../entities/project-settings/billing-address.entity';
import { Project } from '../../../entities/project.entity';
import { User } from '../../../entities/user.entity';
import { MembershipRole } from '../../../entities/membership.entity';

@Injectable({
  providedIn: 'root'
})
export class TeamsService extends ApiService {
  private endpoint = 'teams';

  public getAll(perPage = 100, page = 1): Observable<PaginatedResponse<TeamEntity>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString()
    };
    return this.http.get<PaginatedResponse<TeamEntity>>(this.endpoint, { params });
  }

  public get(id: string): Observable<TeamEntity> {
    return this.http.get<{ data: TeamEntity }>(this.endpoint + '/' + id).pipe(map(res => res.data));
  }

  public create(name: string): Observable<TeamEntity> {
    return this.http
      .post<{ data: TeamEntity }>(this.endpoint, { name })
      .pipe(map(res => res.data));
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<undefined>(this.endpoint + '/' + id);
  }

  public inviteUserToTeam(
    team: TeamEntity,
    email: string,
    role: MembershipRole,
    project_ids: number[] = []
  ): Observable<TeamInvitationEntity> {
    if (role === 'admin') {
      // tslint:disable-next-line:no-parameter-reassignment
      project_ids = [];
    }
    return this.http.post<TeamInvitationEntity>(`${this.endpoint}/${team.id}/members`, {
      email,
      role,
      project_ids
    });
  }

  public deleteMember(team: TeamEntity, member: TeamMemberEntity) {
    return this.http.delete(`${this.endpoint}/${team.id}/members/${member.id}`);
  }

  public getMyMembership(team: TeamEntity): Observable<TeamMemberEntity> {
    return this.http.get<{ data: TeamMemberEntity }>(`${this.endpoint}/${team.id}/membership`).pipe(map(r => r.data));
  }

  public updateMember(team: TeamEntity, member: TeamMemberEntity): Observable<TeamMemberEntity> {
    return this.http.put<TeamMemberEntity>(`${this.endpoint}/${team.id}/members/${member.id}`, member);
  }

  public updateInvitation(team: TeamEntity, invitation: TeamInvitationEntity): Observable<TeamInvitationEntity> {
    return this.http
      .put<{ data: TeamInvitationEntity }>(`${this.endpoint}/${team.id}/invitations/${invitation.id}`, invitation)
      .pipe(map(r => r.data));
  }

  public recreateInvitation(team: TeamEntity, invitation: TeamInvitationEntity): Observable<TeamInvitationEntity> {
    return this.http.post<{ data: TeamInvitationEntity }>(`${this.endpoint}/${team.id}/members`, invitation).pipe(map(r => r.data));
  }

  public deleteInvitation(team: TeamEntity, invitation: TeamInvitationEntity): Observable<undefined> {
    return this.http.delete<undefined>(`${this.endpoint}/${team.id}/invitations/${invitation.id}`);
  }

  public getPaymentIntent(team: TeamEntity): Observable<{ client_secret: string }> {
    return this.http
      .post<{ data: { client_secret: string } }>(`${this.endpoint}/${team.id}/subscription/payment_intent`, {})
      .pipe(map(res => res.data));
  }

  public getBillingAddress(team: TeamEntity): Observable<BillingAddress> {
    return this.http.get<{ data: BillingAddress }>(`${this.endpoint}/${team.id}/billing_address`, {}).pipe(map(res => res.data));
  }

  public updateBillingAddress(team: TeamEntity, billingAddress: BillingAddress): Observable<BillingAddress> {
    return this.http
      .post<{ data: BillingAddress }>(`${this.endpoint}/${team.id}/billing_address`, billingAddress)
      .pipe(map(res => res.data));
  }

  public addPaymentMethodAndSubscribe(team: TeamEntity, payment_method: any, plan: string) {
    return this.http
      .post<{ data: Project }>(`${this.endpoint}/${team.id}/subscription`, {
        payment_method,
        plan
      })
      .pipe(map(res => res.data));
  }
  public subscribe(team: TeamEntity, plan: string) {
    return this.http
      .post<{ data: Project }>(`${this.endpoint}/${team}/subscription`, {
        plan
      })
      .pipe(map(res => res.data));
  }

  public cancelSubscription(team: TeamEntity, subscriptionId: number) {
    return this.http.delete<{ data: Project }>(`${this.endpoint}/${team.id}/subscription/${subscriptionId}`).pipe(map(res => res.data));
  }

  public resumeSubscription(team: TeamEntity, subscriptionId: number) {
    return this.http
      .put<{ data: Project }>(`${this.endpoint}/${team.id}/subscription/${subscriptionId}/resume`, {})
      .pipe(map(res => res.data));
  }

  public getPendingMemberInvitations(
    team: TeamEntity,
    pageConfig: {
      page: number;
      per_page: number;
      sort_by: string;
      sort_dir: 'asc' | 'desc';
      search: string;
    }
  ): Observable<PaginatedResponse<TeamInvitationEntity>> {
    return this.http.get<PaginatedResponse<TeamInvitationEntity>>(`${this.endpoint}/${team.id}/invitations`, {
      params: ApiService.parsePageConfig(pageConfig)
    });
  }

  public getMembers(
    teamId: string,
    pageConfig: {
      page: number;
      per_page: number;
      sort_by: string;
      sort_dir: 'asc' | 'desc';
      search: string;
    }
  ): Observable<PaginatedResponse<TeamMemberEntity>> {
    return this.http.get<PaginatedResponse<TeamMemberEntity>>(`${this.endpoint}/${teamId}/members`, {
      params: ApiService.parsePageConfig(pageConfig)
    });
  }

  public update(team: Partial<TeamEntity>) {
    return this.http.put<{ data: TeamEntity }>(this.endpoint + '/' + team.id, team).pipe(map(res => res.data));
  }
}
