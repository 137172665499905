<div [ngSwitch]="templateSetting?.type" class="pz-2">
  <app-text-setting
    (ngModelChange)="update($event)"
    [hasDefaultValue]="hasDefaultValue"
    *ngSwitchCase="templateSettingTypes.text"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [twoColumns]="twoColumns"
    class="form-row"
    ngDefaultControl
  >
  </app-text-setting>

  <app-text-area-setting
    (ngModelChange)="update($event)"
    [hasDefaultValue]="hasDefaultValue"
    *ngSwitchCase="templateSettingTypes.textarea"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [twoColumns]="twoColumns"
    class="form-row"
    ngDefaultControl
  >
  </app-text-area-setting>

  <app-boolean-setting
    (ngModelChange)="update($event)"
    [hasDefaultValue]="hasDefaultValue"
    *ngSwitchCase="templateSettingTypes.boolean"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [twoColumns]="twoColumns"
    class="form-row"
    ngDefaultControl
  >
  </app-boolean-setting>

  <app-image-setting
    (ngModelChange)="update($event)"
    [hasDefaultValue]="hasDefaultValue"
    *ngSwitchCase="templateSettingTypes.image"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [project]="project"
    [twoColumns]="twoColumns"
    [fullWidth]="fullWidth"
    class="form-row"
    ngDefaultControl
  >
  </app-image-setting>

  <app-color-setting
    (ngModelChange)="update($event)"
    [hasDefaultValue]="hasDefaultValue"
    *ngSwitchCase="templateSettingTypes.color"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [twoColumns]="twoColumns"
    class="form-row"
    ngDefaultControl
  >
  </app-color-setting>

  <app-select-setting
    (ngModelChange)="update($event)"
    *ngSwitchCase="templateSettingTypes.select"
    [hasDefaultValue]="hasDefaultValue"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [options]="options"
    [twoColumns]="twoColumns"
    class="form-row"
    ngDefaultControl
  >
  </app-select-setting>

  <app-html-setting
    (updated)="update($event)"
    [hasDefaultValue]="hasDefaultValue"
    *ngSwitchCase="templateSettingTypes.html"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [twoColumns]="true"
    class="form-row"
    ngDefaultControl
  >
  </app-html-setting>

  <app-action-setting
    (ngModelChange)="update($event)"
    [hasDefaultValue]="hasDefaultValue"
    *ngSwitchCase="templateSettingTypes.action"
    [actionType]="templateSetting.action_type"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [project]="project"
    [twoColumns]="twoColumns"
    class="form-row"
    ngDefaultControl
  >
  </app-action-setting>

  <app-event-setting
    (ngModelChange)="update($event)"
    [hasDefaultValue]="hasDefaultValue"
    *ngSwitchCase="templateSettingTypes.event"
    [defaultValue]="templateSetting.value"
    [name]="templateSetting.name"
    [ngModel]="model"
    [project]="project"
    [twoColumns]="twoColumns"
    class="form-row"
    ngDefaultControl
  >
  </app-event-setting>

  <ng-container *ngSwitchCase="templateSettingTypes.repeater">
    <app-repeater-setting
      (dataChanged)="model = $event; update(model)"
      *ngIf="useRepeater"
      [data]="model"
      [project]="project"
      [setting]="templateSetting"
      [twoColumns]="twoColumns"
      class="form-row"
      ngDefaultControl
    >
    </app-repeater-setting>
  </ng-container>
</div>
