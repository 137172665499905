import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private subject = new BehaviorSubject<boolean>(true);

  public get opened(): Observable<boolean> {
    return this.subject.asObservable();
  }

  public toggle() {
    this.subject.next(!this.subject.getValue());
  }
}
