<div class="col-12">
  <label [for]="name" [innerText]="name" class="font-weight-bold"></label>

  <button (click)="switchToDefault()" *ngIf="html !== null && hasDefaultValue" mat-button type="button">Use Default</button>

  <button (click)="update('')" *ngIf="html === null" mat-button type="button"><i class="fa fa-edit"></i></button>
</div>
<div class="col-12 pb-4">
  <mat-quill
    *ngIf="html !== null"
    (ngModelChange)="update($event)"
    [ngModel]="html"
    [id]="name"
    placeholder="Enter text here..."
    pageBuilderMode="true"
  ></mat-quill>

  <iframe
    *ngIf="html === null && hasDefaultValue"
    [srcdoc]="defaultValue"
    class="border rounded w-100"
    disabled=""
    frameborder="0"
  ></iframe>
</div>
