<nav class="navbar navbar-expand-lg fixed-top">
  <a class="navbar-brand py-0" routerLink="/">
    <img [src]="'/assets/images/' + environment.header.logo" alt="HEADER_LOGO" style="max-height: 40px;" />
  </a>

  <app-badge *ngIf="environment.envName !== 'prod'" [color]="'danger'">
    {{ environment.envName }}
  </app-badge>

  <button *ngIf="currentProject" class="nav-item" mat-button [routerLink]="''">
    <i class="fas fa-long-arrow-alt-left mr-2"></i>Back to Teamlist
  </button>

  <button class="nav-item" mat-button [matMenuTriggerFor]="teamsMenu" (menuOpened)="loadTeams()">
    {{ currentProject?.name || 'Select a Project' }}<i class="fas fa-chevron-down ml-2"></i>
  </button>

  <mat-menu #teamsMenu="matMenu">
    <ng-container *ngIf="teams; else loadingTemplate">
      <ng-container *ngFor="let team of teams">
        <button mat-menu-item [routerLink]="'/teams/' + team.id!" class="text-primary">{{ team.name }}</button>
        <button *ngFor="let project of team.projects" mat-menu-item [routerLink]="'/project/' + project.id">
          <span class="ml-2 text-muted small">{{ project.id }}</span>
          {{ project.name }}
        </button>
        <button mat-menu-item [disabled]="true" *ngIf="((team.projects) || []).length === 0">No Projects</button>
      </ng-container>
    </ng-container>

    <ng-template #loadingTemplate>
      <app-page-loading-indicator class="d-block" style="width: 264px;"></app-page-loading-indicator>
    </ng-template>
  </mat-menu>

  <div class="flex-grow-1 d-none d-md-block text-center text-light">
    <h2 class="mb-0 font-weight-lighter">{{ title }}</h2>
  </div>

  <button (click)="sidenavService.toggle()" class="navbar-toggler" type="button">
    <!-- <span class="navbar-toggler-icon"></span> -->
    <i aria-hidden="true" class="fa fa-bars text-muted"></i>
  </button>
  <div class="flex-grow-0 collapse navbar-collapse">
    <ul class="navbar-nav ml-auto">
      <a *ngIf="defaultDomainHostName" class="nav-item domain-nav-item" href="https://{{ defaultDomainHostName }}" mat-button target="_blank">
        {{ defaultDomainHostName }}<i class="fas fa-sm fa-external-link-alt pl-2"></i>
      </a>
<!--      <button (click)="cheatSheetService.toggleCheatSheet()" class="nav-item" mat-icon-button>-->
<!--        <i class="fas fs-1-4 fa-question-circle"></i>-->
<!--      </button>-->

      <ng-container *ngIf="user">
        <button [matMenuTriggerFor]="userMenu" class="nav-item user-dropdown" mat-button>
          {{ user.first_name }} {{ user.last_name }}
          <i class="fas fa-chevron-down ml-2"></i>
        </button>
        <mat-menu #userMenu="matMenu">
          <button [routerLink]="['/users/' + user.id]" mat-menu-item><i class="fa fa-fw fa-user"></i> {{ 'Profile' }}</button>

          <button (click)="onLoggedout()" [routerLink]="['/login']" mat-menu-item>
            <i class="fa fa-fw fa-power-off"></i> {{ 'Log Out' }}
          </button>
        </mat-menu>
      </ng-container>
    </ul>
  </div>
</nav>
