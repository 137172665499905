import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Project } from 'src/app/entities/project.entity';
import { User } from 'src/app/entities/user.entity';
import { ProjectService } from 'src/app/services/api/methods/project.service';
import { CheatSheetService } from 'src/app/services/cheatsheet.service';
import { HeaderTitleService } from 'src/app/services/header-title.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { environment } from '../../../../environments/environment';
import { TeamEntity } from '../../../entities/team/team.entity';
import { AuthenticationService } from '../../../services/api/methods/authentication.service';
import { TeamsService } from '../../../services/api/methods/teams.service';
import { ProjectResolver } from '../../project/project.resolver';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public environment = environment;
  public currentProject?: Project;
  public projects: Project[] = [];
  public teams?: TeamEntity[];
  public user?: User;
  public defaultDomainHostName?: string;
  public title: string = '';

  public ngOnInit(): void {
    this.headerTitleService.getTitle().subscribe(title => (this.title = title));
  }

  public onLoggedout(): void {
    this.authenticationService.logout().subscribe();
  }

  public loadTeams(): void {
    this.teamService.getAll().subscribe(res => {
      this.teams = res.data;
    });
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private teamService: TeamsService,
    public cheatSheetService: CheatSheetService,
    private headerTitleService: HeaderTitleService,
    public sidenavService: SidenavService,
    private projectResolver: ProjectResolver
  ) {
    this.projectResolver.project$.subscribe(project => {
      if (project) {
        this.currentProject = project;
        if (this.currentProject && (this.currentProject.domains || []).find(domain => domain.default_domain)) {
          this.defaultDomainHostName = this.currentProject.domains.find(domain => domain.default_domain)?.hostname;
        } else {
          this.defaultDomainHostName = undefined;
        }
      } else {
        this.defaultDomainHostName = undefined;
        this.currentProject = undefined;
      }
    });

    this.authenticationService.getMe().subscribe(user => {
      this.user = user;
    });
  }
}
