import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from 'src/app/entities/pages/page.entity';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { TableSort } from 'src/app/entities/table-sort.entity';
import { SortsAdapter } from 'src/app/utils/sorts-adapter';
import { Project } from '../../../entities/project.entity';
import { ApiService } from '../api.service';
import { HttpCacheService } from '../http-cache.service';
import { ProjectService } from './project.service';
import { Section } from 'src/app/entities/pages/section.entity';
import { PageMeta } from '../../../entities/pages/page-meta.entity';

@Injectable({
  providedIn: 'root'
})
export class PagesService extends ApiService {
  private endpoint = 'pages';

  public getAll(
    project: Project,
    perPage: number = 100,
    page: number = 1,
    search: string = '',
    sorts: TableSort[] = [],
    include: string = '',
    locale: string = 'de'
  ): Observable<PaginatedResponse<Page>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts),
      include: include
    };
    return this.http
      .get<PaginatedResponse<Page>>(`projects/${project.id}/${this.endpoint}?locale=${locale}`, {
        params
      })
      .pipe(
        map(res => ({
          ...res,
          data: res.data.map(p => new Page().deserialize(p, project))
        }))
      );
  }

  public get(projectId: number, pageId: number, locale: string = 'de'): Observable<Page> {
    return this.http
      .get<{ data: Page; meta: PageMeta }>(`projects/${projectId}/${this.endpoint}/${pageId}?locale=${locale}`)
      .pipe(map(res => new Page().deserialize({ ...res.data, meta: res.meta })));
  }

  public copy(projectId: number, pageId: number): Observable<Page> {
    return this.http
      .get<{ data: Page; meta: PageMeta }>(`projects/${projectId}/${this.endpoint}/${pageId}/copy`)
      .pipe(map(res => new Page().deserialize({ ...res.data, meta: res.meta })));
  }

  public createPage(projectId: number, page: Partial<Page>): Observable<Page> {
    const editPage = { ...page.serialize!() };
    if (editPage.sections) {
      editPage.sections = editPage.sections.map((section, index) => {
        return {
          template_data: section.template_data,
          template_id: section.template_id
        };
      });
    }
    return this.http
      .post<{ data: Page; meta: PageMeta }>(`projects/${projectId}/${this.endpoint}`, editPage)
      .pipe(map(res => new Page().deserialize({ ...res.data, meta: res.meta })));
  }

  public updatePage(projectId: number, page: Partial<Page>): Observable<Page> {
    const editPage = { ...page.serialize!() };
    if (editPage.sections) {
      editPage.sections = editPage.sections.map((section, index) => {
        return {
          template_data: section.template_data,
          template_id: section.template_id
        };
      });
    }
    return this.http
      .put<{ data: Page; meta: PageMeta }>(`projects/${projectId}/${this.endpoint}/${page.id}`, editPage)
      .pipe(map(res => new Page().deserialize({ ...res.data, meta: res.meta })));
  }

  public delete(pageId: number, projectId: number): Observable<Page> {
    return this.http.delete<Page>(`projects/${projectId}/${this.endpoint}/${pageId}`);
  }

  public getSections(pageId: number, projectId: number, locale: string = 'de'): Observable<Section[]> {
    return this.http
      .get<{ data: Section[] }>(`projects/${projectId}/${this.endpoint}/${pageId}/sections?locale=${locale}`)
      .pipe(map(res => res.data));
  }

  public updateSections(pageId: number, projectId: number, sections: Section[], locale: string = 'de'): Observable<Section[]> {
    return this.http
      .put<{ data: Section[] }>(`projects/${projectId}/${this.endpoint}/${pageId}/sections?locale=${locale}`, { data: sections })
      .pipe(map(res => res.data));
  }

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected cache: HttpCacheService,
    protected storage: LocalStorageService,
    private projectService: ProjectService
  ) {
    super(http, router, cache, storage);
  }
}
