import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Guest } from '../../../entities/events/guest.entity';
import { GuestCustomFieldEntity } from '../../../entities/events/guest-custom-field.entity';
import {FormFieldType} from '../../../entities/resources/form-field.entity';

@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuestInfoComponent implements OnInit, OnChanges {
  @Input('guest') public guest!: Guest;
  @Input('salesData') public salesData?: object;

  public fields: GuestCustomFieldEntity[] = [];
  public formFieldTypes = FormFieldType;

  public get fieldsWithoutCustomFirstLastName() {
    let curatedGuestFields = [...this.guest.fields];
    if (this.guest.custom_first_name_field?.uuid) {
      const firstNameFieldsIndex = this.guest.fields.findIndex(field => field.uuid === this.guest.custom_first_name_field?.uuid);
      if (firstNameFieldsIndex >= 0) curatedGuestFields.splice(firstNameFieldsIndex, 1);
    }
    if (this.guest.custom_last_name_field?.uuid) {
      const lastNameFieldsIndex = this.guest.fields.findIndex(field => field.uuid === this.guest.custom_last_name_field?.uuid);
      if (lastNameFieldsIndex >= 0) curatedGuestFields.splice(lastNameFieldsIndex, 1);
    }
    curatedGuestFields.sort((a,b) => {
      if(a.definition.order < b.definition.order) return -1;
      if(a.definition.order > b.definition.order) return 1;
      return 0;
    });
    return curatedGuestFields;
  }

  public ngOnInit() {
    this.cureateGuestFields();
    this.cdr.markForCheck();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.cureateGuestFields();
    this.cdr.markForCheck();
  }

  private cureateGuestFields() {
    this.fields = this.guest.fields;
    const fieldsWithDefinitions: GuestCustomFieldEntity[] = [];
    const fieldsWithoutDefinitions: GuestCustomFieldEntity[] = [];
    this.guest.fields.forEach(value => {
      if (value.definition) {
        fieldsWithDefinitions.push(value);
      } else {
        fieldsWithoutDefinitions.push(value);
      }
    });
    if (fieldsWithDefinitions.length > 1) {
      fieldsWithDefinitions.sort((a, b) => {
        if (a.definition.order < b.definition.order) {
          return -1;
        } else if (a.definition.order > b.definition.order) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    this.fields = [...fieldsWithDefinitions, ...fieldsWithoutDefinitions];
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
