<nav class="sidebar">
  <div class="list-group">
    <a
      *ngIf="project?.id"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="['router-link-active']"
      [routerLink]="'/project/' + project?.id"
      class="list-group-item main project-overview first-level"
    >
      <i class="fas mr-1 fa-chart-line"></i><span>Dashboard</span>
    </a>

    <!--
            <div class="p-3">
              <input type="text" class="form-control search-field" placeholder="Search" />
            </div>
        -->
    <!-- <a [routerLink]="'/Aproject/' + project?.id + '/dashboard'" [routerLinkActive]="['router-link-active']" class="list-group-item first-level">
          <sectionIndex class="fas mr-1 fa-home"></sectionIndex>
          <span>{{ 'Dashboard'  }}</span>
        </a> -->

    <div *ngIf="project?.id" class="nested-menu" [routerLinkActive]="['router-link-active']" #rla="routerLinkActive">
      <div class="d-flex justify-content-between toggler-container {{ rla.isActive ? 'router-link-active' : '' }}">
        <a
          (click)="toggleCollapsed('sales')"
          [attr.aria-expanded]="!collapsed.sales"
          aria-controls="collapsed.sales"
          class="list-group-item main"
        >
          <span>
            <i class="fas mr-1 fa-calendar-alt"></i>
            <span>{{ 'Sales' }}</span>
          </span>
          <span class="float-right">
            <i [ngClass]="{ 'fa-chevron-up': !collapsed.sales, 'fa-chevron-down': collapsed.sales }" class="fas mr-1"></i>
          </span>
        </a>
      </div>

      <div [ngbCollapse]="collapsed.sales" class="submenu" id="collapsed.sales">
        <a
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/sales/dashboard']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-calendar-alt"></i>
          <span>Dashboard</span>
        </a>
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/sales/contacts']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-calendar"></i>
          <span>Contacts</span>
        </a>
      </div>
    </div>

    <div *ngIf="project?.id" class="nested-menu" [routerLinkActive]="['router-link-active']" #rla="routerLinkActive">
      <div class="d-flex justify-content-between toggler-container {{ rla.isActive ? 'router-link-active' : '' }}">
        <a
          (click)="toggleCollapsed('events')"
          [attr.aria-expanded]="!collapsed.events"
          aria-controls="collapsed.events"
          class="list-group-item main"
        >
          <span>
            <i class="fas mr-1 fa-calendar-alt"></i>
            <span>{{ 'Eventmanagement' }}</span>
          </span>
          <span class="float-right">
            <i [ngClass]="{ 'fa-chevron-up': !collapsed.events, 'fa-chevron-down': collapsed.events }" class="fas mr-1"></i>
          </span>
        </a>
      </div>

      <div [ngbCollapse]="collapsed.events" class="submenu" id="collapsed.events">
        <a
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/events']"
          class="list-group-item"
          (click)="clearNotificationsForEvent(NotificationType.EVENT_BOOKING_CREATED)"
        >
          <i class="fas mr-1 fa-calendar-alt"></i>
          <span
            [matBadgeOverlap]="false"
            [matBadge]="notificationsByEvent && notificationsByEvent[NotificationType.EVENT_BOOKING_CREATED]?.length"
            >Events</span
          >
        </a>
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/meetings/']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-calendar"></i>
          <span>Meetings</span>
        </a>
        <!--<a
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/events/add']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-plus"></i>
          <span>Create New Event</span>
        </a>-->

        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/events/guests']"
          class="list-group-item"
          (click)="clearNotificationsForEvent(NotificationType.GUEST_CREATED)"
        >
          <i class="fas mr-1 fa-users"></i>
          <span [matBadgeOverlap]="false" [matBadge]="notificationsByEvent && notificationsByEvent[NotificationType.GUEST_CREATED]?.length"
            >Guests</span
          >
        </a>
      </div>
    </div>

    <div
      *ngIf="project?.id && membershipRole != MembershipRoles.CheckIn"
      class="nested-menu"
      [routerLinkActive]="['router-link-active']"
      #rla="routerLinkActive"
    >
      <div class="d-flex justify-content-between toggler-container {{ rla.isActive ? 'router-link-active' : '' }}">
        <a
          (click)="toggleCollapsed('pages')"
          [attr.aria-expanded]="!collapsed.pages"
          aria-controls="collapsed.pages"
          class="list-group-item main"
        >
          <span>
            <i class="fas mr-1 fa-book-open"></i>
            <span>{{ 'Pages' }}</span>
          </span>
          <span class="float-right"
            ><i [ngClass]="{ 'fa-chevron-up': !collapsed.pages, 'fa-chevron-down': collapsed.pages }" class="fas mr-1"></i
          ></span>
        </a>
      </div>

      <div [@smoothCollapse]="collapsed.pages ? 'closed' : 'open'" [ngbCollapse]="collapsed.pages" class="submenu" id="collapsed.pages">
        <a
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="'/project/' + project?.id + '/pages'"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-list-ul"></i>
          <span>{{ 'Pages' }}</span>
        </a>
        <!--<a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/pages/add']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-edit"></i>
          <span>{{ 'Create New Page' }}</span>
        </a>-->

        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/pages/menu']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-compass"></i>
          <span>{{ 'Menus' }}</span>
        </a>

        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/pages/redirects']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-reply"></i>
          <span>{{ 'Redirects' }}</span>
        </a>

        <a
          *ngIf="(!activeThemeId && membershipRole == MembershipRoles.Admin) || isAdmin"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/pages/themes']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-paint-brush"></i>
          <span>{{ 'Themes' }}</span>
        </a>

        <a
          *ngIf="(activeThemeId && membershipRole == MembershipRoles.Admin) || isAdmin"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/pages/themes/' + activeThemeId]"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-paint-brush"></i>
          <span>{{ 'Theme Editor' }}</span>
        </a>
      </div>
    </div>

    <div
      *ngIf="project?.id && membershipRole != MembershipRoles.CheckIn"
      class="nested-menu"
      [routerLinkActive]="['router-link-active']"
      #rla="routerLinkActive"
    >
      <div class="d-flex justify-content-between toggler-container {{ rla.isActive ? 'router-link-active' : '' }}">
        <a
          class="list-group-item main"
          (click)="toggleCollapsed('resources')"
          [attr.aria-expanded]="!collapsed.resources"
          aria-controls="collapsed.resources"
        >
          <span>
            <i class="fas mr-1 fa-home"></i>
            <span>{{ 'Resources' }}</span>
          </span>
          <span class="float-right">
            <i [ngClass]="{ 'fa-chevron-up': !collapsed.resources, 'fa-chevron-down': collapsed.resources }" class="fas mr-1"></i>
          </span>
        </a>
      </div>

      <div [ngbCollapse]="collapsed.resources" class="submenu" id="collapsed.resources">
        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/resources/audiences']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-circle"></i>
          <span>Audiences</span>
        </a>

        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/resources/emails']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-at"></i>
          <span>{{ 'Mails' }}</span>
        </a>

        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/resources/registration-forms']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-edit"></i>
          <span>{{ 'Registration Forms' }}</span>
        </a>

        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/resources/event-forms']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-edit"></i>
          <span>{{ 'Booking Forms' }}</span>
        </a>

        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/resources/question-forms']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-edit"></i>
          <span>{{ 'Question Forms' }}</span>
        </a>

        <a
          *ngIf="
            membershipRole == MembershipRoles.Admin ||
            membershipRole == MembershipRoles.Expert ||
            membershipRole == MembershipRoles.Eventmanager ||
            isAdmin
          "
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/resources/form-submissions']"
          class="list-group-item"
          (click)="clearNotificationsForEvent(NotificationType.FORM_SUBMISSION_CREATED)"
        >
          <i class="fas mr-1 fa-edit"></i>
          <span
            [matBadgeOverlap]="false"
            [matBadge]="notificationsByEvent && notificationsByEvent[NotificationType.FORM_SUBMISSION_CREATED]?.length"
            >{{ 'Question Submissions' }}</span
          >
        </a>

        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/resources/campaigns']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-funnel-dollar"></i>
          <span>{{ 'Campaigns' }}</span>
        </a>

        <a
          *ngIf="membershipRole == MembershipRoles.Admin || membershipRole == MembershipRoles.Expert || isAdmin"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/resources/campaign-rules']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-funnel-dollar"></i>
          <span>{{ 'Campaign Rules' }}</span>
        </a>
      </div>
    </div>

    <div *ngIf="project?.id" class="nested-menu" [routerLinkActive]="['router-link-active']" #rla="routerLinkActive">
      <div class="d-flex justify-content-between toggler-container {{ rla.isActive ? 'router-link-active' : '' }}">
        <a
          class="list-group-item main"
          (click)="toggleCollapsed('notifications')"
          [attr.aria-expanded]="!collapsed.notifications"
          aria-controls="collapsed.notifications"
        >
          <span>
            <i class="fas mr-1 fa-bell"></i>
            <span>{{ 'Notifications' }}</span>
          </span>
          <span class="float-right">
            <i [ngClass]="{ 'fa-chevron-up': !collapsed.notifications, 'fa-chevron-down': collapsed.notifications }" class="fas mr-1"></i>
          </span>
        </a>
      </div>

      <div [ngbCollapse]="collapsed.notifications" class="submenu" id="collapsed.notifications">
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/project/' + project?.id + '/notifications']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-bell"></i>
          <span>Notifications</span>
        </a>
      </div>
    </div>

    <div *ngIf="isAdmin" class="nested-menu" [routerLinkActive]="['router-link-active']" #rla="routerLinkActive">
      <div class="d-flex justify-content-between toggler-container {{ rla.isActive ? 'router-link-active' : '' }}">
        <a
          (click)="toggleCollapsed('admin')"
          [attr.aria-expanded]="!collapsed.admin"
          aria-controls="collapsed.admin"
          class="list-group-item main"
        >
          <span>
            <i class="fas mr-1 fa-cog"></i>
            <span>{{ 'Admin' }}</span>
          </span>
          <span class="float-right">
            <i [ngClass]="{ 'fa-chevron-up': !collapsed.admin, 'fa-chevron-down': collapsed.admin }" class="fas mr-1"></i>
          </span>
        </a>
      </div>

      <div [ngbCollapse]="collapsed.admin" class="submenu" id="collapsed.admin">
        <a
          *ngIf="project?.id"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="'/project/' + project.id + '/settings'"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-cog"></i><span>Project Settings</span>
        </a>

        <a
          *ngIf="project?.id"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="'/project/' + project.id + '/files'"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-upload"></i><span>Files upload</span>
        </a>

        <a
          *ngIf="project?.team?.id"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="'/teams/' + project.team.id!.toString()"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-users"></i><span>Team</span>
        </a>

        <a
          *ngIf="!project?.team?.id"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="'/teams'"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-users"></i><span>Teams</span>
        </a>
        <a
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLinkActive]="['router-link-active']"
          [routerLink]="['/users']"
          class="list-group-item"
        >
          <i class="fas mr-1 fa-user"></i>
          <span>{{ 'Users' }}</span>
        </a>
      </div>
    </div>

    <a class="list-group-item main project-overview" (click)="onLoggedout()" [routerLink]="['/login']">
      <i class="fas mr-1 fa fa-power-off"></i><span>Logout</span>
    </a>
  </div>
  <!--  <div class="toggle-button text-right"(click)="sidenavService.toggle()">
        <i class="fa fa-fw "
           [ngClass]="{ 'fa-angle-double-left' : !open, 'fa-angle-double-right' : open}"></i>
    </div>-->
</nav>
