import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Project } from 'src/app/entities/project.entity';
import { BaseSettingComponent } from '../base-setting.component';
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-image-setting',
  templateUrl: './image-setting.component.html',
  styleUrls: ['./image-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageSettingComponent extends BaseSettingComponent {
  @Input() public fullWidth: boolean = false;
  @Input() @Required public project!: Project;
}
