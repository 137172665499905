export enum TemplateSettingType {
  boolean = 'boolean',
  text = 'text',
  image = 'image',
  textarea = 'textarea',
  html = 'html',
  select = 'select',
  color = 'color',
  repeater = 'repeater',
  form_submissions = 'form_submissions',
  event = 'event',
  event_list = 'event_list',
  session = 'session',
  session_list = 'session_list',

  action = 'action'
}
