export enum ColumnVisalizationType {
  date = 'date',
  badge = 'badge',
  link = 'link',
  iconOnly = 'iconOnly',
  file = 'file',
  select = 'select',
  uuid = 'uuid',
  list = 'list',
  statusLight = 'statusLight',
  iconText = 'iconText',
  textMap = 'textMap',
  textOnly = 'textOnly'
}
