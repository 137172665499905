import { Moment } from 'moment';
import { Action } from '../actions/action.model';
import { Deserializable } from '../deserializable.model';
import { Model } from '../model';
import { Theme } from '../pages/theme/theme.entity';
import { FormFieldGroup } from './form-field-group.entity';
import { FormField } from './form-field.entity';
import { AvailableLanguagesEntity } from '../enums/available-languages.entity';
import { MetaData } from '../meta-data.entity';

export const GLOBAL_FORM_FIELD_CSS_CLASSES = 'globalFormFieldCssClasses';
export const GLOBAL_FORM_LABEL_CSS_CLASSES = 'globalFormLabelCssClasses';
export const GLOBAL_FORM_INPUT_CSS_CLASSES = 'globalFormInputCssClasses';

export class Form extends Model implements Deserializable<Form> {
  public version = 2;
  public created_at?: Moment;
  public updated_at?: Moment;
  public id?: number;
  public uuid?: string;
  public project_id?: number;
  public title!: string;
  public css_class!: string | null;
  public redirect_url!: string | null;
  public submit_text!: string | null;
  public form_field_groups: FormFieldGroup[] = [
    {
      title: null,
      css_class: null,
      fields: []
    }
  ];
  public captcha_enabled = false;
  public paginate_groups = false;
  public actions!: Action[];
  public published_at?: Moment | null;
  public success_message!: string;
  public locale?: string;
  public meta?: MetaData;

  /** @deprecated */
  public fields!: FormField[];
  /** @deprecated */
  public _allowed_actions!: Action[];
  /** @deprecated */
  public published!: boolean;

  protected _dateAttributes = ['created_at', 'updated_at'];

  public deserialize(input: any): this {
    super.deserialize(input);
    this.form_field_groups = input.form_field_groups?.map((group: FormFieldGroup) => ({
      ...group,
      fields: group.fields.map(fieldInput => new FormField(fieldInput.type, fieldInput.name).deserialize(fieldInput))
    }));
    return this;
  }

  constructor(theme?: Theme) {
    super();
    let formFieldCssClassesSettingValue;
    if (theme) {
      const formFieldCssClassesSetting = theme.theme_settings.find(e => e.name === GLOBAL_FORM_FIELD_CSS_CLASSES);
      if (formFieldCssClassesSetting !== undefined) {
        formFieldCssClassesSettingValue = formFieldCssClassesSetting.value;
      }
    }
    this.form_field_groups.forEach(group => {
      group.css_class = formFieldCssClassesSettingValue;
    });
  }
}
