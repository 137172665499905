import { Component, Input } from '@angular/core';
import { TemplateSettingType } from 'src/app/entities/pages/theme/template-setting-type.enum';
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-template-setting-type-icon',
  templateUrl: './template-setting-type-icon.component.html',
  styleUrls: ['./template-setting-type-icon.component.scss']
})
export class TemplateSettingTypeIconComponent {
  @Input() @Required public type!: TemplateSettingType;
  public TemplateSettingType = TemplateSettingType;
}
