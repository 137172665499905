import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { ApiInterceptor } from './api/api.interceptor';
import { AuthenticationService } from './api/methods/authentication.service';
import { RequestCacheInterceptor } from './api/request-cache.interceptor';
import { WithCredentialsInterceptor } from './api/with-credentials.interceptor';
import { InMemoryDataService } from './in-memory-data.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@NgModule({
  imports: [
    NgxWebstorageModule.forRoot({
      prefix: '_',
      separator: '|',
      caseSensitive: true
    }),
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
      delay: 0,
      passThruUnknownUrl: true,
      apiBase: '/123'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      deps: [LocalStorageService, AuthenticationService, ToastrService, Router],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCacheInterceptor,
      multi: true
    },
    JwtHelperService
  ]
})
export class ServiceModule {}
