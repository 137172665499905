// Git Gist: https://gist.github.com/superelement/69677823acf20d45fdbb11a051486936
export function groupBy(arr: any[], prop: string) {
    const groupedObj = {}; // object to return, keeps track of order
    const wrapObj = {}; // temporary object used for grouping

    arr.forEach(function (item) {

        // gets property name to group by and converts it to a string for grouping purposes
        let propName = item[prop];
        if(propName) {
            propName = propName.toString();

            // checks if group exists already and creates a new group if not, pushing it into the array to return ('newArr')
            if (!wrapObj[propName]) {
                wrapObj[propName] = [];

                groupedObj[propName] = (wrapObj[propName]);
            }

            // adds item to the group
            wrapObj[propName].push(item);
        } else {
            console.warn("utils -> groupByKeepOrder", "Property '" + prop + "' not found within object. It will be ignored from the output.", item);
        }
    });

    return groupedObj;
}


