import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges, OnInit
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import Quill from 'quill';
import {Required} from '../../../utils/required-input';

@Component({
  selector: 'mat-quill',
  templateUrl: 'mat-quill.component.html',
  styleUrls: ['mat-quill.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MatQuillComponent }],
  host: {
    '[id]': 'id'
  }
})
export class MatQuillComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy, OnInit {
  static nextId = 0;
  @ViewChild('content') contentInput!: HTMLInputElement;
  @Input() public ngModel?: string | null = null;
  @Output() textOnlySource = new EventEmitter<string>();

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'mat-quill-input';
  id = `mat-quill-input-${MatQuillComponent.nextId++}`;
  describedBy = '';
  classes = 'editorContentContainer';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      //['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      //[{ 'direction': 'rtl' }],                         // text direction

      //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      //[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      //[{ 'font': [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button

      //TODO: add at least image support
      //['link', 'image', 'video']                         // link and image, video
      ['link']
    ]
  };
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: { content }
    } = this.parts;

    return !content;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return true;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = 'Insert text here...';

  @Input()
  get pageBuilderMode(): boolean {
    return this._pageBuilderMode;
  }

  set pageBuilderMode(value: boolean) {
    this._pageBuilderMode = value;

    if (this._pageBuilderMode) {
      this.modules.toolbar = [
        ['bold', 'italic', 'underline', 'strike'],

        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],

        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ['clean'],
        ['link']
      ];
    }

    this.stateChanges.next();
  }

  private _pageBuilderMode: boolean = false;

  @Input()
  get useBootstrapTextAlignClasses(): boolean {
    return this._useBootstrapTextAlignClasses;
  }

  set useBootstrapTextAlignClasses(value: boolean) {
    this._useBootstrapTextAlignClasses = value;

    if (this._useBootstrapTextAlignClasses) {
      // @TODO: text is a reserved keyword in quill, any custom class with a reserved keyword is removed when the editor is initialized
      // If we can't use bootstrap classes, might aswell use the quill classes and add the styles to the themes directly
      // const Align = Quill.import('attributors/class/align');
      // Align.keyName = 'text';
      // Quill.register(Align, true);
    }

    this.stateChanges.next();
  }

  private _useBootstrapTextAlignClasses: boolean = false;

  @Input()
  get defaultWrapTagName(): string {
    return this._defaultWrapTagName;
  }

  set defaultWrapTagName(value: string) {
    this._defaultWrapTagName = value;

    const Block = Quill.import('blots/block');
    Block.tagName = value;
    Quill.register(Block, true);

    this.stateChanges.next();
  }

  private _defaultWrapTagName: string = 'P';

  @Input()
  get hint(): string | null {
    return this._hint;
  }

  set hint(value: string | null) {
    this._hint = value;
    this.stateChanges.next();
  }

  private _hint: string | null = null;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    const {
      value: { content }
    } = this.parts;

    return content;
    /* if (this.parts.valid) {
            const {
                value: { content }
            } = this.parts;
            console.log(content);
            return content;
        } */
    return null;
  }
  set value(inputContent: string | null) {
    const content = inputContent || null;
    this.parts.setValue({ content });
    this.stateChanges.next();
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,

    @Optional() @Self() public ngControl: NgControl
  ) {
    this.parts = formBuilder.group({
      content: [this.ngModel, [Validators.required]]
    });

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if(this.ngModel) {
      this.parts.setValue({content: this.ngModel});
      this.stateChanges.next();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    this._focusMonitor.focusVia(this.contentInput, 'program');
  }

  writeValue(quillEntity: string | null): void {
    this.value = quillEntity;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(quill): void {
    this.textOnlySource.emit(quill.text);
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
