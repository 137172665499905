import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Template } from 'src/app/entities/pages/theme/template.entity';
import { Theme } from 'src/app/entities/pages/theme/theme.entity';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { ApiService } from '../api.service';
import {TemplateSetting} from '../../../entities/pages/theme/template-setting.enitity';

interface UploadResponse {
  status?: UploadStatus;
  message?: any;
}

export enum UploadStatus {
  progress = 'progress',
  success = 'success',
  error = 'error'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends ApiService {
  public getAll(projectId: number, perPage: number = 100, page: number = 1): Observable<Theme[]> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString()
    };
    return this.http
      .get<PaginatedResponse<Theme>>(`projects/${projectId}/themes`, { params })
      .pipe(map(resp => resp.data));
  }

  public get(themeId: number, projectId: number, include: 'content'[] = ['content']): Observable<Theme> {
    return this.http
      .get<{ data: Theme }>(`projects/${projectId}/themes/${themeId}`, { params: { include: include.toString() } })
      .pipe(map(resp => resp.data));
  }

  public uploadTheme(projectId: number, data: FormData): Observable<UploadResponse> {
    return this.http
      .post(`projects/${projectId}/themes-upload`, data, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress: {
              const progress = Math.round((event.loaded * 100) / event.total!);
              return { status: UploadStatus.progress, message: progress };
            }
            case HttpEventType.Response:
              return { status: UploadStatus.success, message: event.body };
            default:
              return { status: undefined, message: undefined };
          }
        })
      );
  }

  public updateTheme(projectId: number, theme: Theme): Observable<Theme> {
    return this.http.put<{ data: Theme }>(`projects/${projectId}/themes/${theme.id}`, theme).pipe(map(d => d.data));
  }

  public updateThemeSettings(projectId: number, themeId: number, themeSettings: TemplateSetting[]): Observable<Theme> {
    return this.http.put<{ data: Theme }>(`projects/${projectId}/themes/${themeId}`, themeSettings).pipe(map(d => d.data));
  }

  public downloadTheme(projectId: number, theme: Theme): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/themes/${theme.id}/download`).pipe(
      tap(res => {
        window.open(res.data.url);
      }),
      map(() => undefined)
    );
  }

  public deleteTheme(projectId: number, themeId: number): Observable<Theme> {
    return this.http.delete<Theme>(`projects/${projectId}/themes/${themeId}`);
  }

  public getTemplatesForTheme(
    themeId: number,
    projectId: number,
    perPage: number = 20,
    page: number = 1,
    include: 'content'[] = []
  ): Observable<Template[]> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      include: include?.toString()
    };

    return this.http
      .get<PaginatedResponse<Template>>(`projects/${projectId}/themes/${themeId}/templates`, { params })
      .pipe(map(res => res.data));
  }

  public updateTemplate(projectId: number, themeId: number, template: Template): Observable<Template> {
    // remove old invalid attributes
    // template.settings = template.settings.map((setting: TemplateSetting) => {
    //   const retSetting: TemplateSetting = {
    //     name: setting.name,
    //     type: setting.type,
    //     value: setting.value,
    //     options: setting.options,
    //     form_id: setting.form_id
    //   };
    //   if (setting.fields) {
    //     retSetting.fields = setting.fields.map(
    //       (fieldSetting: TemplateSetting) => {
    //         return {
    //           name: fieldSetting.name,
    //           type: fieldSetting.type,
    //           value: fieldSetting.value,
    //           options: fieldSetting.options
    //         };
    //       }
    //     );
    //   }
    //   if (setting.options) {
    //     retSetting.options = setting.options;
    //   }
    //   if (setting.form_id) {
    //     retSetting.form_id = setting.form_id;
    //   }
    //   return retSetting;
    // });

    return this.http.put<Template>(`projects/${projectId}/themes/${themeId}/templates/${template.id}`, template);
  }

  public saveTemplate(projectId: number, themeId: number, template: Template): Observable<Template> {
    return this.http.post<Template>(`projects/${projectId}/themes/${themeId}/templates`, template);
  }

  public deleteTemplate(projectId: number, themeId: number, template: Template): Observable<void> {
    return this.http.delete<undefined>(`projects/${projectId}/themes/${themeId}/templates/${template.id}`);
  }
}
