import { Routes } from '@angular/router';
import { IsLoggedIn } from './guard/is-logged-in.guard';
import { IsLoggedOut } from './guard/is-logged-out.guard';
import { IsMembershipRole } from './guard/is-membership-role.guard';
import { MembershipRole } from './entities/membership.entity';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'teams', pathMatch: 'full' },
  {
    path: 'reset',
    loadChildren: () => import('./components/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'teams',
    loadChildren: () => import('./components/team/team.module').then(m => m.TeamModule),
    canActivate: [IsLoggedIn, IsMembershipRole],
    data: { membershipRoles: [MembershipRole.Admin] }
  },
  {
    path: 'users',
    loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule),
    canActivate: [IsLoggedIn, IsMembershipRole],
    data: { membershipRoles: [MembershipRole.Admin] }
  },
  {
    path: 'login',
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule),
    canActivate: [IsLoggedOut]
  },
  {
    path: 'not-found',
    loadChildren: () => import('./components/not-found/not-found.module').then(m => m.NotFoundModule)
  },

  {
    path: 'create-project',
    loadChildren: () => import('./components/create-project/create-project.module').then(m => m.CreateProjectModule),
    canActivate: [IsLoggedIn]
  },
  {
    path: 'project',
    loadChildren: () => import('./components/project/project.module').then(m => m.ProjectModule),
    canActivate: [IsLoggedIn]
  },
  { path: '**', redirectTo: 'not-found' }
];
