import { Action } from './action.model';

/**
 * Should be implemented by all actions interacting with forms
 */
export abstract class FormAction extends Action {
  public payload?: {
    form?: {
      title: string;
    } | null;
    form_id: number | null;
  };
}
