import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Notification } from 'src/app/entities/notifications/notification.entity';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpCacheService } from '../http-cache.service';
import { LocalStorageService } from 'ngx-webstorage';
import { PusherService } from '../../pusher.service';
import { Project } from '../../../entities/project.entity';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends ApiService {
  private endpoint = 'notifications';

  private readonly _unreadNotifications = new BehaviorSubject<Notification[]>([]);

  public readonly unreadNotifications$ = this._unreadNotifications.asObservable();

  public getAllAndSubscribeToNew(project: Project): Observable<PaginatedResponse<Notification>> {
    this.pusherService.projectEvents(project).subscribe(not => {
      console.log(' new notification from websocket', not);
      const current = this._unreadNotifications.getValue();
      current.push(not);
      this._unreadNotifications.next(current);
    });
    const params = {
      per_page: '100',
      page: '1',
      only_unread: 'true'
    };
    return this.http
      .get<PaginatedResponse<Notification>>(`projects/${project.id}/${this.endpoint}`, {
        params
      })
      .pipe(
        map(res => ({
          ...res,
          data: res.data.map(notifications => new Notification().deserialize(notifications))
        })),
        tap(res => {
          this._unreadNotifications.next(res.data);
        })
      );
  }

  public markNotificationsAsRead(projectId: number, notificationUuids: string[]): Observable<undefined> {
    return this.http
      .post<undefined>(`projects/${projectId}/${this.endpoint}/mark-as-read`, { notifications: notificationUuids })
      .pipe(
        tap(() => {
          this._unreadNotifications.next(
            this._unreadNotifications.getValue().filter(notification => !notificationUuids.includes(notification.id!))
          );
        })
      );
  }

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected httpCache: HttpCacheService,
    protected storage: LocalStorageService,
    protected pusherService: PusherService
  ) {
    super(http, router, httpCache, storage);
  }
}
