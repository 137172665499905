import { TableSort } from '../entities/table-sort.entity';

export class SortsAdapter {
  public static sortsToString(sorts?: TableSort[]): string {
    return sorts ? sorts.map(sort => (sort.dir === 'desc' ? '-' : '') + sort.prop).toString() : '';
  }

  public static stringToSorts(string: string): TableSort[] {
    return string?.split(',')?.map(part => ({
      dir: part.startsWith('-') ? 'desc' : 'asc',
      prop: part.startsWith('-') ? part.substr(1) : part
    }));
  }
}
