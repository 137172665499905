<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ label }}</mat-label>
  <sat-datepicker #picker [calendarFooterComponent]="timeFooter" [closeAfterSelection]="false" [rangeMode]="rangeMode"></sat-datepicker>
  <sat-datepicker-toggle [for]="picker" matSuffix></sat-datepicker-toggle>

  <input
    #input="ngModel"
    (click)="picker.open()"
    (ngModelChange)="rangeChange($event)"
    [ngModel]="range"
    [appInputServerErrors]="serverErrors"
    [matDatepickerFilter]="filter"
    [placeholder]="placeholder"
    [required]="required"
    [satDatepicker]="picker"
    matInput
    readonly
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>

  <mat-error *ngIf="input.errors?.inputServerErrors">{{ input.errors?.inputServerErrors }}</mat-error>
</mat-form-field>
