<mat-form-field appearance="outline" class="w-100">
  <mat-label>{{ name }}</mat-label>

  <textarea
    (ngModelChange)="update($event)"
    *ngIf="ngModel !== null"
    [(ngModel)]="ngModel"
    [id]="name"
    [name]="name"
    [placeholder]="name"
    matInput
    rows="4"
    type="text"
  ></textarea>

  <textarea *ngIf="ngModel === null" [placeholder]="name" [value]="defaultValue" disabled matInput></textarea>

  <button (click)="switchToDefault()" *ngIf="ngModel !== null && hasDefaultValue" mat-button matSuffix type="button">Use Default</button>

  <button (click)="update('')" *ngIf="ngModel === null" mat-button matSuffix type="button"><i class="fa fa-edit"></i></button>
</mat-form-field>
