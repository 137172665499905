import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseSettingComponent } from '../base-setting.component';

@Component({
  selector: 'app-select-setting',
  templateUrl: './select-setting.component.html',
  styleUrls: ['./select-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSettingComponent extends BaseSettingComponent {
  @Input() public options: string[] = [];
}
