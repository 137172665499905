import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { TableSort } from '../../../entities/table-sort.entity';
import { SortsAdapter } from '../../../utils/sorts-adapter';

@Injectable({
  providedIn: 'root'
})
export class ExportService extends ApiService {
  public guests(projectId: number): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/export/guests/url`).pipe(
      tap(res => {
        //window.open(res.data.url);
        return true;
      }),
      map(() => undefined)
    );
  }

  public table(
    endpoint: string = '',
    columns: string[],
    selectedRows: string[]
  ): Observable<void> {
    const params = {
      fields: columns,
      items: selectedRows
    };
    return this.http.post<{ data: { url: string } }>(endpoint, params).pipe(
      tap(res => {
        return true;
      }),
      map(() => undefined)
    );
  }

  public meetingBookings(projectId: number, meetingId: string): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/export/meeting-bookings/${meetingId}/url`).pipe(
      tap(res => {
        //window.open(res.data.url);
        return true;
      }),
      map(() => undefined)
    );
  }

  public meetingChats(projectId: number, meetingId: string): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/export/meeting-chats/${meetingId}`).pipe(
      tap(res => {
        return true;
      }),
      map(() => undefined)
    );
  }

  public eventBookings(projectId: number, eventId: number | undefined): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/export/event-bookings/${eventId}/url`).pipe(
      tap(res => {
        return true;
      }),
      map(() => undefined)
    );
  }

  public eventChats(projectId: number, eventId: number | undefined): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/export/event-chats/${eventId}`).pipe(
      tap(res => {
        return true;
      }),
      map(() => undefined)
    );
  }

  public projectBookmarks(projectId: number): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/export/project-bookmarks`).pipe(
      tap(res => {
        return true;
      }),
      map(() => undefined)
    );
  }

  public projectInsights(projectId: number): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/export/project-insights`).pipe(
      tap(res => {
        return true;
      }),
      map(() => undefined)
    );
  }

  public formSubmissions(projectId: number, formId: number | undefined): Observable<void> {
    return this.http.get<{ data: { url: string } }>(`projects/${projectId}/export/form-submissions/${formId}`).pipe(
      tap(res => {
        return true;
      }),
      map(() => undefined)
    );
  }
}
