import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/api/methods/authentication.service';
import { ProjectService } from '../services/api/methods/project.service';
import { TeamsService } from '../services/api/methods/teams.service';
import { Observable } from 'rxjs';

@Injectable()
export class IsMembershipRole implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private teamsService: TeamsService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if (route.params.project) {
        this.projectService.getProject(route.params.project).subscribe(project => {
          this.teamsService.getMyMembership(project.team).subscribe(membership => {
            if (route.data.membershipRoles.includes(membership.role)) {
              observer.next(true);
            } else {
              alert('Invalid user type');
              this.router.navigate(['/login']);
              observer.next(false);
            }
          });
        });
      } else {
        observer.next(true);
      }
    });
  }
}
