import { Deserializable } from '../deserializable.model';
import { Model } from '../model';

export const HO_EMAIL_FIELD_NAME = 'ho-email-field';
export const HO_PASSWORD_FIELD_NAME = 'ho-password-field';
export const HO_PASSWORD_CONFIRMATION_FIELD_NAME = 'ho-password-confirmation-field';
export const HO_GUEST_LANGUAGE_FIELD = 'ho-guest-language-field';
export const HO_PROFILE_IMAGE_FIELD_NAME = 'ho-profile-image-field';

export enum FormFieldType {
  text = 'text',
  email = 'email',
  password = 'password',
  checkbox = 'checkbox',
  number = 'number',
  phone = 'phone',
  date = 'date',
  radio = 'radio',
  file = 'file',
  range = 'range',
  select = 'select',
  textarea = 'textarea',
  html = 'html'
}

export class FormField extends Model implements Deserializable<FormField> {
  public readonly uuid?: string;
  public initial_value!: string | number | string[] | number[] | null;
  public type!: FormFieldType;
  public name?: string;
  public label!: string | null;
  public textOnlySource!: string | null;
  public placeholder!: string | null;
  public helptext!: string | null;
  public css_class!: string | null;
  public input_css_class!: string | null;
  public label_css_class!: string | null;
  public choices?: { label: string; value: string }[];
  public multiple?: boolean;
  /** Only set if type=range otherwise null*/
  public min?: number | null;
  /** Only set if type=range otherwise null */
  public max?: number | null;
  public upload_url?: string;
  public required = false;
  public disabled?: boolean;
  public guest_custom_field_uuid!: string | null;

  public setType(value: FormFieldType): void {
    this.type = value;
    switch (value) {
      case FormFieldType.select:
        this.choices = [];
        break;
      case FormFieldType.html:
        this.label = 'HTML';
        break;
      default:
        break;
    }
  }

  constructor(type: FormFieldType, name?: string) {
    super();
    this.setType(type);

    if (name === HO_EMAIL_FIELD_NAME) {
      this.required = true;
      this.label = 'Email';
    }

    if (name === HO_PASSWORD_FIELD_NAME) {
      this.required = true;
      this.label = 'Password';
    }

    if (name === HO_PASSWORD_CONFIRMATION_FIELD_NAME) {
      this.required = true;
      this.label = 'Repeat Password';
    }

    if (name === HO_GUEST_LANGUAGE_FIELD) {
      this.required = true;
      this.label = 'Current Guest Language';
    }

    this.name = name;
  }
}
