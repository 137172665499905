export interface User {
  id: number;
  first_name: string;
  last_name: string;
  company: string;
  phone: string;
  email: string;
  is_admin: boolean;
}

export enum UserRole {
  Admin = 'admin',
  User = 'user'
}
