import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Guest } from 'src/app/entities/events/guest.entity';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { Audience } from 'src/app/entities/resources/audience.entity';
import { TableSort } from 'src/app/entities/table-sort.entity';
import { SortsAdapter } from 'src/app/utils/sorts-adapter';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AudiencesService extends ApiService {
  private endpoint = 'audiences';

  public getAll(
    projectId: number,
    perPage: number = 20,
    page: number = 1,
    search: string = '',
    sorts: TableSort[] = []
  ): Observable<PaginatedResponse<Audience>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts)
    };
    return this.http
      .get<PaginatedResponse<Audience>>(`projects/${projectId}/${this.endpoint}`, { params })
      .pipe(
        map(res => ({
          ...res,
          data: res.data.map(guest => new Audience().deserialize(guest))
        }))
      );
  }

  public get(projectId: number, audienceId: number): Observable<Audience> {
    return this.http
      .get<{ data: Audience }>(`projects/${projectId}/${this.endpoint}/${audienceId}`)
      .pipe(map(res => new Audience().deserialize(res.data)));
  }

  public create(projectId: number, audience: Partial<Audience>): Observable<Audience> {
    return this.http
      .post<{ data: Audience }>(`projects/${projectId}/${this.endpoint}`, audience.serialize!())
      .pipe(map(res => new Audience().deserialize(res.data)));
  }

  public update(projectId: number, audience: Partial<Audience>): Observable<Audience> {
    return this.http
      .put<{ data: Audience }>(`projects/${projectId}/${this.endpoint}/${audience.id}`, audience.serialize!())
      .pipe(map(res => new Audience().deserialize(res.data)));
  }

  public delete(projectId: number, audienceId: number): Observable<void> {
    return this.http.delete<undefined>(`projects/${projectId}/${this.endpoint}/${audienceId}`);
  }

  public getGuestsOfAudience(
    projectId: number,
    audienceId: number,
    perPage: number = 20,
    page: number = 1,
    search: string = '',
    sorts: TableSort[] = []
  ): Observable<PaginatedResponse<Guest>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts)
    };
    return this.http
      .get<PaginatedResponse<Guest>>(`projects/${projectId}/${this.endpoint}/${audienceId}/guests`, { params })
      .pipe(
        map(res => ({
          ...res,
          data: res.data.map(guest => new Guest().deserialize(guest))
        }))
      );
  }

  public addGuestToAudience(projectId: number, audienceId: number, guestId: number): Observable<Audience> {
    return this.http.post<{ data: Audience }>(`projects/${projectId}/${this.endpoint}/${audienceId}/guests/${guestId}`, {}).pipe(
      take(1),
      map(res => new Audience().deserialize(res.data))
    );
  }

  public sendEmailToAudience(projectId: number, audienceId: number, emailId: number): Observable<Audience> {
    return this.http
      .post<{ data: Audience }>(`projects/${projectId}/${this.endpoint}/${audienceId}/sendEmail/${emailId}`, {})
      .pipe(map(res => new Audience().deserialize(res.data)));
  }

  public removeGuestFromAudience(projectId: number, audienceId: number, guestId: number) {
    return this.http.delete<undefined>(`projects/${projectId}/${this.endpoint}/${audienceId}/guests/${guestId}`, {}).pipe(take(1));
  }

  public syncGuestAudiences(projectId: number, guestId: number, audienceIds: number[]): Observable<void> {
    return this.http
      .post<void>(`projects/${projectId}/${this.endpoint}/guests/${guestId}/batch`, { audiences: audienceIds })
      .pipe(take(1));
  }
}
