import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RepeaterData } from 'src/app/entities/pages/section.entity';
import { TemplateSettingRepeater } from 'src/app/entities/pages/theme/template-setting.enitity';
import { Project } from 'src/app/entities/project.entity';
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-repeater-setting',
  templateUrl: './repeater-setting.component.html',
  styleUrls: ['./repeater-setting.component.scss']
})
export class RepeaterSettingComponent implements OnInit {
  public collapsed = true;

  @Input() @Required public project!: Project;
  @Input() public twoColumns = true;
  @Input() @Required public setting!: TemplateSettingRepeater;
  @Input() public data: RepeaterData = [];

  @Output() public readonly dataChanged: EventEmitter<RepeaterData> = new EventEmitter<RepeaterData>();

  public settingChanged(row, key, value) {
    this.data[row][key] = value;
    this.dataChanged.emit(this.data);
    this.cdr.detectChanges();
  }

  public addRepeaterRow() {
    this.collapsed = false;
    let newRepeaterRow: { [key: string]: string } = {};
    this.setting.fields.forEach(field => {
      newRepeaterRow[field.name] = field.value;
    });
    this.data = [...(this.data || []), newRepeaterRow];
    this.dataChanged.next(this.data);
  }

  public removeRow(index: number) {
    this.data.splice(index, 1);
    this.dataChanged.next(this.data);
  }

  public trackByFn(index: any, item: any) {
    return index;
  }

  public getRepeaterFieldFromName(name: string) {
    return this.data.find(field => field.name === name);
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }

  public ngOnInit() {
    if (this.data && this.data.length <= 1) {
      this.collapsed = false;
    }
  }

  constructor(private cdr: ChangeDetectorRef, public dialog: MatDialog) {}
}
