import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ColumnData } from '../entities/column-data.entity';
import { PaginatedResponse } from '../entities/paginated-response.entity';
import { ProjectService } from './api/methods/project.service';
import { flatten } from '../utils/object-flat-unflat';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  private fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private fileExtension = '.xlsx';
  private perPage = 100;

  /**
   *
   * @param project
   * @param endpoint Endpoint where to find data to export
   * @param fileName
   * @param columns Columns which should be included in the export
   * @param modificationFunction Function which can be used to modify the entries before export
   */
  public fetchAllAndExportExcel(
    endpoint: string,
    fileName: string,
    columns?: ColumnData[],
    modificationFunction?: (input: any) => any
  ): void {
    this.http
      .get<PaginatedResponse<any>>(endpoint, {
        params: { per_page: this.perPage.toString() }
      })
      .pipe(
        switchMap(res => {
          const otherPages$ = Array.from(Array(res.meta.last_page - 1).keys()).map(i =>
            this.http.get<PaginatedResponse<any>>(endpoint, {
              params: {
                per_page: this.perPage.toString(),
                page: (i + 2).toString()
              }
            })
          );
          return forkJoin([of(res), ...otherPages$]);
        })
      )
      .subscribe(res => {
        const aggregatedData = res.map(page => page.data);
        const flatData = aggregatedData.reduce((acc, curr) => acc.concat(curr), []);
        this.exportExcel(flatData, fileName, columns, modificationFunction);
      });
  }

  public exportExcel(jsonData: any[], fileName: string, columns?: ColumnData[], modificationFunction?: (input: any) => any): void {
    if (modificationFunction) {
      jsonData = jsonData.map(modificationFunction);
    }
    const flattenedData = jsonData.map(json => flatten(json, { safe: true }));
    let reducedData = flattenedData.map(entry =>
      this.reduceObjectToKeys(
        entry,
        columns?.map(data => data.name)
      )
    );
    reducedData = this.arraysToString(reducedData, columns);

    let reducedColumns = {};
    columns?.forEach(col => {
      reducedColumns = { ...reducedColumns, [col.name]: col.label };
    });
    reducedData = reducedData.map(data => {
      return this.renameKeys(data, reducedColumns);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(reducedData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array'
    });

    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  private renameKeys(obj: Record<string, any>, columnData: Record<string, any>): any {
    const keyValues = Object.keys(obj).map(key => {
      const newKey = columnData[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  }

  private reduceObjectToKeys(object: { [key: string]: any }, keys: string[] = []): { [key: string]: any } {
    if (keys.length === 0) {
      return object;
    }
    return Object.keys(object)
      .filter(key => keys.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: object[key]
        };
      }, {});
  }

  /** Compares columns and data and uses the column.itemKey to reduce any data array of object entries to strings  */
  private arraysToString(
    data: { [key: string]: any }[],
    columns: ColumnData[] = []
  ): {
    [key: string]: any;
  }[] {
    return data.map(entry => {
      Object.keys(entry).forEach(key => {
        const keyCol = columns.find(col => col.name === key);
        if (!keyCol) {
          return;
        }
        if (Array.isArray(entry[keyCol.name])) {
          entry[keyCol.name] = entry[keyCol.name].map(e => (keyCol.itemKey ? e[keyCol.itemKey] : e)).toString();
        }
      });
      return entry;
    });
  }

  constructor(private http: HttpClient, private projectService: ProjectService) {}
}
