import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent {
  @Input() public imagePreviewSrc?: string | null;
  @Input() public loading = false;
  @Input() public deleting = false;

  @Output() public onFileDrop = new EventEmitter();
  @Output() public removeFile = new EventEmitter();
}
