<h4 mat-dialog-title>Upload Theme</h4>
<div mat-dialog-content>
  <form (ngSubmit)="onUpload()" [formGroup]="form">
    <mat-progress-bar
      *ngIf="status === 'progress' || status === 'success'"
      [value]="progress"
      class="my-3"
      mode="determinate"
    ></mat-progress-bar>

    <input (change)="onFileChange($event)" class="mb-2 d-block" name="theme" type="file" />
    <button color="primary" mat-flat-button type="submit">Upload</button>

    <div *ngIf="status === 'error'" class="alert alert-danger my-2">
      {{ error?.data?.message }}
    </div>
  </form>
</div>
<div class="d-flex" mat-dialog-actions>
  <button (click)="dialogRef.close()" mat-flat-button>
    Cancel
  </button>
  <span class="flex-grow-1"></span>
</div>
