export enum CampaignRuleTrackingTypes {
  pageView = 'pageView',
  formSubmitted = 'formSubmitted',
  eventBooked = 'eventBooked',
  meetingBooked = 'meetingBooked',
  audienceJoined = 'audienceJoined',
  comment = 'customComment',
  sale = 'customSale'
}

export const CampaignRuleTrackingTypesDisplay = {
  [CampaignRuleTrackingTypes.pageView]: 'Page view',
  [CampaignRuleTrackingTypes.formSubmitted]: 'Form Submit',
  [CampaignRuleTrackingTypes.eventBooked]: 'Book Event',
  [CampaignRuleTrackingTypes.meetingBooked]: 'Book Meeting',
  [CampaignRuleTrackingTypes.audienceJoined]: 'Audience'
};

export interface CampaignRule {
  id: string;
  title: string;
  tracking_type: CampaignRuleTrackingTypes;
  source_id: number | null;
  source_uuid: string | null;
  source_name: string | null;
  score: number;
  limit_to_once_per_24_hours: boolean;
  created_at: string;
  updated_at: string;
  campaign_rule_score?: number;

  single_campaign_campaign_rule?: {
    id: string;
    campaign_id: string;
    campaign_rule_id: string;
    recommended: boolean;
    initial: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  };

  done_once_by_guest?: boolean;
  event_type?: string;
  campaign_campaign_rule_initial: boolean;
}
