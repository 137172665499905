import * as moment from 'moment';
import { Moment } from 'moment';
import { BookingAction } from '../actions/booking-action.entity';
import { CheckInAction } from '../actions/check-in-action.entity';
import { Deserializable } from '../deserializable.model';
import { Model } from '../model';
import { Attachment } from '../attachments.entity';
import { AppointmentPublishStatesEntity } from '../enums/appointment-publish-states.entity';
import { Audience } from '../resources/audience.entity';
import { EventMeta } from './event-meta.entity';

export class Event extends Model implements Deserializable<Event> {
  public id?: number;
  public uuid?: string;
  public created_at?: Moment;
  public updated_at?: Moment;
  public title: string = '';
  public subtitle: string = '';
  public calendar_description: string = '';
  public description: string = '';
  public short_description: string = '';
  public publish_status: AppointmentPublishStatesEntity = AppointmentPublishStatesEntity.draft;
  public published_at: Moment | null = null;
  public event_date_start: Moment = moment().startOf('hour').add(1, 'hour');
  public event_date_end: Moment = moment().startOf('hour').add(2, 'hour');
  public checkin_date_start: Moment = moment().startOf('hour');
  public checkin_date_end: Moment = moment().startOf('hour').add(45, 'minutes');
  public sessionIds: number[] = [];
  public max_guests: number | null = null;
  public booking_actions: BookingAction[] = [];
  public check_in_actions: CheckInAction[] = [];
  public imageUuids: string[] = [];
  readonly attachments: Attachment[] = [];
  public visible_for_audience_ids: number[] | null = [];
  public category_ids: number[] | null = [];
  public audiences: Audience[] = [];
  public organizer_name: string | null = null;
  public organizer_email: string | null = null;
  public link: string = '';
  public secondary_link: string = '';
  public locale?: string;
  public managed_externally?: boolean = false;
  public meta?: EventMeta;

  protected _dateAttributes = [
    'created_at',
    'updated_at',
    'published_at',
    'event_date_start',
    'event_date_end',
    'checkin_date_start',
    'checkin_date_end'
  ];

  public serialize(): any {
    return {
      ...super.serialize(),
      booking_actions: this.booking_actions.map(action => action.serialize())
    };
  }

  public deserialize(input: any): this {
    super.deserialize(input);
    if (input.booking_actions && Array.isArray(input.booking_actions)) {
      this.booking_actions = input.booking_actions.map((action: BookingAction) => new BookingAction().deserialize(action));
    }
    return this;
  }

  constructor() {
    super();
    this.booking_actions = [new BookingAction()];
  }
}
