import { Directive, HostListener, Input } from '@angular/core';
import { ColumnData } from '../entities/column-data.entity';
import { ProjectService } from '../services/api/methods/project.service';
import { ExportService } from '../services/export.service';
import { Required } from '../utils/required-input';

@Directive({
  selector: '[appExport]'
})
export class ExportDirective {
  @Input('appExport') @Required public data: any[] = [];
  @Input() @Required public endpoint!: string;
  @Input() @Required public fileName!: string;
  @Input() @Required public columns: ColumnData[] = [];
  @Input() public modificationFunction?: (input: any) => any;

  @HostListener('click', ['$event'])
  public onClick(): void {
    console.log("endpoint", this.endpoint);
    console.log("fileName", this.fileName);
    console.log("columns", this.columns);
    console.log("data", this.data);
    if (this.endpoint) {
      this.exportService.fetchAllAndExportExcel(this.endpoint, this.fileName, this.columns, this.modificationFunction);
    } else if (this.data) {
      this.exportService.exportExcel(this.data, this.fileName, this.columns, this.modificationFunction);
    }
  }
  constructor(private exportService: ExportService, private projectService: ProjectService) {}
}

