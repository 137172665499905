import { Moment } from 'moment';
import { Deserializable } from '../deserializable.model';
import { Model } from '../model';
import { Domain } from '../project-settings/domain.entity';
import { Project } from '../project.entity';
import { Section, TemplateData } from './section.entity';
import { PageMeta } from './page-meta.entity';

export class Page extends Model implements Deserializable<Page> {
  public id?: number;
  public created_at?: Moment;
  public updated_at?: Moment;
  public title!: string;
  public slug!: string;
  public url!: string;
  public description!: string;
  public project!: Project;
  public published!: boolean;
  public published_at!: Moment | null;
  public locale?: string;
  public meta?: PageMeta;
  public exclude_from_sitemap: boolean = false;
  public sitemap_priority?: string = '0.5';
  public uuid?: string;

  /** @deprecated */
  public sections?: Section[];

  public template_data: { [name: string]: TemplateData | null } = {};
  public meta_tags: {} = {};
  public template!: { id: number; status: string; settings: {} };
  public is_login_page!: boolean;
  public is_password_reset_page!: boolean;
  public only_if_authenticated!: boolean | null;
  public visible_for_audience_ids: number[] | null = [];
  public category_ids: number[] | null = [];
  protected _dateAttributes = ['created_at', 'updated_at', 'published_at'];
  /* Custom Attribute */
  public visible_in_sitemap?: boolean;

  public copy(): Page {
    const randomPageNr = Math.floor(Math.random() * (Math.floor(9999999) - Math.ceil(0))) + Math.ceil(0);
    return Object.assign(Object.create(Object.getPrototypeOf(this)), {
      ...this,
      id: undefined,
      title: this.title += ` Copy${randomPageNr}`,
      slug: this.slug += `-copy${randomPageNr}`
    });
  }

  public deserialize(input: any, project?: Project): this {
    super.deserialize(input);
    if (project) {
      const defaultDomain = project.domains.find((domain: Domain) => domain.default_domain);
      if (defaultDomain) {
        this.url = 'https://' + defaultDomain.hostname + '/' + input.slug;
      }
    }
    if (!input.template_data || input.template_data.length === 0) {
      this.template_data = {};
    }
    return this;
  }
}
