import { NgModule } from '@angular/core';
import { ExportDirective } from './export.directive';
import { InputServerErrorDirective } from './input-server-error.directive';
import { ResizableDirective } from './resizable.directive';

@NgModule({
  declarations: [ResizableDirective, InputServerErrorDirective, ExportDirective],
  exports: [ResizableDirective, InputServerErrorDirective, ExportDirective]
})
export class DirectivesModule {}
