import { Deserializable } from '../deserializable.model';
import { Model } from '../model';
import { ActionType } from './action-type.enum';

export class Action extends Model implements Deserializable<Action> {
  /** @deprected */
  public id?: string;
  public readonly uuid?: string;
  public type!: ActionType;
  public actionable_id?: number;
  public payload?: { [key: string]: any };
}
