import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../components/elements/confirmation-dialog/confirmation-dialog.component';

export interface ConfirmDialogData {
  title: string;
  message: string;
  btnOkText: string;
  btnCancelText: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm' | 'lg' = 'sm'
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { title, message, btnOkText, btnCancelText }
    });

    return dialogRef.afterClosed();
  }
}
