export class Alert {
  public type!: AlertType;
  public message!: string;
  public buttonText?: string | null;
  public callback?: CallableFunction | null;
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}
