import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'integerRange'
})
export class IntegerRangePipe implements PipeTransform {
  public transform(value: number, args?: any): number[] {
    const array = Array.from(Array(value + 1).keys());
    array.shift();
    return array;
  }
}
