<mat-form-field appearance="outline" class="col-12">
  <mat-label>{{ name }}</mat-label>
  <input
    (ngModelChange)="update($event)"
    *ngIf="ngModel !== null"
    [(ngModel)]="ngModel"
    [id]="name"
    [name]="name"
    [placeholder]="name"
    matInput
    type="color"
  />
  <input *ngIf="ngModel === null" [ngModel]="defaultValue" [placeholder]="name" disabled matInput type="color" />
  <div matSuffix>
    <button (click)="switchToDefault()" *ngIf="ngModel !== null && hasDefaultValue" class="suffix-button" mat-button type="button">
      Use Default
    </button>

    <button (click)="update(defaultValue)" *ngIf="ngModel === null" class="suffix-button" mat-button type="button">
      <i class="fa fa-edit"></i>
    </button>
  </div>
</mat-form-field>
