// tslint:disable-next-line:no-duplicate-imports
import * as moment from 'moment';
import { Moment } from 'moment';

export class Model {
  protected _dateAttributes = ['created_at', 'updated_at'];

  public momentToString(input: Moment | null): string | null {
    return input ? moment(input).toISOString() : null;
  }

  public stringToMoment(input: string): Moment | null {
    const output = moment(input);
    return output.isValid() ? output : null;
  }

  public serialize(): any {
    const dates = {};
    this._dateAttributes.map(attribute => {
      dates[attribute] = this.momentToString(this[attribute]);
    });
    const serialized = {
      ...this,
      ...dates
    };
    delete serialized._dateAttributes;
    return serialized;
  }

  public deserialize(input: any): this {
    Object.assign(this, input);
    this._dateAttributes.map(attribute => {
      this[attribute] = this.stringToMoment(input[attribute]);
    });
    return this;
  }
}
