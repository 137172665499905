import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateData } from 'src/app/entities/pages/section.entity';
import { TemplateSettingType } from 'src/app/entities/pages/theme/template-setting-type.enum';
import { TemplateSetting, TemplateSettingSelect } from 'src/app/entities/pages/theme/template-setting.enitity';
import { Project } from 'src/app/entities/project.entity';
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingComponent implements OnInit {
  @Input() public model?: TemplateData;
  @Input() @Required public project!: Project;
  @Input() public twoColumns = true;
  @Input() public fullWidth = false;
  @Input() public useRepeater = true;
  @Input() public hasDefaultValue = true;

  @Output() public readonly modelChanged: EventEmitter<TemplateData> = new EventEmitter<TemplateData>();
  public options: string[] = [];
  public templateSettingTypes = TemplateSettingType;

  private _templateSetting!: TemplateSetting;

  public get templateSetting(): TemplateSetting {
    return this._templateSetting;
  }

  @Input() @Required public set templateSetting(value: TemplateSetting) {
    if ('options' in value) {
      if (value.options instanceof Array) {
        /* console.warn(`Theme Setting select.options is a object, but should be an array of string`, {
          project: this.project.name,
          setting: value.name,
          options: value.options
        }); */
        this.options = Object.values(value.options);
      } else {
        this.options = (value as TemplateSettingSelect).options;
      }
    }
    this._templateSetting = value;
    this.cdr.markForCheck();
  }

  public update(value: TemplateData | undefined): void {
    this.model = value;
    this.cdr.markForCheck();
    this.modelChanged.emit(this.model);
  }

  public ngOnInit(): void {
    // if the value was not yet defined for the page we use null as a value
    // this indicates to the field that it should inherit the value from the page or section value
    if (this.model === undefined) {
      this.update(undefined);
    }
  }

  public constructor(private cdr: ChangeDetectorRef) {}
}
