import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { Alert, AlertType } from '../entities/alert.entity';

@Injectable({
  providedIn: 'root'
})
export class AlertNotificationsService {
  private subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  public getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  public success(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Success, message, keepAfterRouteChange, null, null);
  }

  public error(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Error, message, keepAfterRouteChange, null, null);
  }

  public info(message: string, keepAfterRouteChange = false, buttonText: string | null = null, callback: any = null) {
    this.alert(AlertType.Info, message, keepAfterRouteChange, buttonText, callback);
  }

  public warn(message: string, keepAfterRouteChange = false, buttonText: string | null = null, callback: any = null) {
    this.alert(AlertType.Warning, message, keepAfterRouteChange, buttonText, callback);
  }

  public alert(type: AlertType, message: string, keepAfterRouteChange = false, buttonText: string | null = null, callback: any) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({
      type: type,
      message: message,
      callback: callback,
      buttonText: buttonText
    });
  }

  public clear() {
    // clear alerts
    this.subject.next();
  }
}
