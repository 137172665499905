import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { Form } from 'src/app/entities/resources/form.entity';
import { TableSort } from 'src/app/entities/table-sort.entity';
import { SortsAdapter } from 'src/app/utils/sorts-adapter';
import { ActionType } from '../../../entities/actions/action-type.enum';
import { FormSubmission } from '../../../entities/resources/form-submission.entity';
import { ApiService } from '../api.service';
import { AvailableLanguagesEntity } from '../../../entities/enums/available-languages.entity';
import { FormMeta } from '../../../entities/resources/form-meta.entity';

@Injectable({
  providedIn: 'root'
})
export class FormsService extends ApiService {
  private endpoint = 'forms';

  public get(projectId: number, formId: number, locale: string = 'de'): Observable<Form> {
    return this.http
      .get<{ data: Form; meta: FormMeta }>(`projects/${projectId}/${this.endpoint}/${formId}?locale=${locale}`)
      .pipe(map(res => new Form().deserialize({ ...res.data, meta: res.meta })));
  }

  public getAll(
    projectId: number,
    perPage: number = 100,
    page: number = 1,
    search: string = '',
    action_type: (ActionType | 'null')[] | 'null' = 'null',
    version: number = 2,
    sorts: TableSort[] = [],
    include: string = '',
    locale: string = 'de'
  ): Observable<PaginatedResponse<Form>> {
    let params: {} = {
      per_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts),
      include: include
    };
    if (action_type && version >= 2) {
      params = { ...params, action_type: action_type.toString() };
    }
    return this.http
      .get<PaginatedResponse<Form>>(`projects/${projectId}/${this.endpoint}?locale=${locale}`, { params })
      .pipe(
        map(res => ({
          ...res,
          data: res.data
            .filter(form => form.version === version) // used while legacy forms in DB
            .map(form => new Form().deserialize(form))
        }))
      );
  }

  public create(projectId: number, form: Partial<Form>): Observable<Form> {
    return this.http
      .post<{ data: Form; meta: FormMeta }>(`projects/${projectId}/${this.endpoint}`, form.serialize!())
      .pipe(map(res => new Form().deserialize({ ...res.data, meta: res.meta })));
  }

  public delete(projectId: number, formId: number): Observable<void> {
    return this.http.delete<undefined>(`projects/${projectId}/${this.endpoint}/${formId}`);
  }

  public update(projectId: number, form: Partial<Form>, locale: string = 'de'): Observable<Form> {
    return this.http
      .put<{ data: Form; meta: FormMeta }>(`projects/${projectId}/${this.endpoint}/${form.id}?locale=${locale}`, form.serialize!())
      .pipe(map(res => new Form().deserialize({ ...res.data, meta: res.meta })));
  }

  public getAllFormSubmissions(
    projectId: number,
    formId: number,
    perPage: number = 100,
    page: number = 1,
    search: string = '',
    sorts: TableSort[] = []
  ): Observable<PaginatedResponse<FormSubmission>> {
    const params = {
      page_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts)
    };

    return this.http
      .get<PaginatedResponse<FormSubmission>>(`projects/${projectId}/${this.endpoint}/${formId}/form-submissions`, { params })
      .pipe(
        map(res => ({
          ...res,
          data: res.data.map(formSubmission => new FormSubmission().deserialize(formSubmission))
        }))
      );
  }

  public deleteFormSubmission(projectId: number, formId: number, formSubmissionId: number): Observable<void> {
    return this.http.delete<void>(`projects/${projectId}/${this.endpoint}/${formId}/form-submissions/${formSubmissionId}`);
  }
}
