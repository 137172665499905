import { Moment } from 'moment';
import { Deserializable } from '../deserializable.model';
import { Model } from '../model';

export class Audience extends Model implements Deserializable<Audience> {
  public id?: number;
  public uuid?: string;
  public title: string | null = null;
  public created_at!: Moment;
  public updated_at!: Moment;
  protected _dateAttributes = ['created_at', 'updated_at'];
  public is_managed: boolean = false;
}
