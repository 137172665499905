import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Action } from 'src/app/entities/actions/action.model';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { ApiService } from '../api.service';
import { HttpCacheService } from '../http-cache.service';
import { ActionType } from '../../../entities/actions/action-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ActionsService extends ApiService {
  private endpoint = 'actions';

  public getAll(projectId: number, perPage: number = 20, page: number = 1, search: string = ''): Observable<PaginatedResponse<Action>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search
    };
    return this.http.get<PaginatedResponse<Action>>(`projects/${projectId}/${this.endpoint}`, { params });
  }

  public get(projectId: number, actionUuid: string): Observable<Action> {
    return this.http.get<{ data: Action }>(`projects/${projectId}/${this.endpoint}/${actionUuid}`).pipe(map(res => res.data));
  }

  public create(projectId: number, action: Partial<Action>): Observable<Action> {
    return this.http
      .post<{ data: Action }>(`projects/${projectId}/${this.endpoint}`, action.serialize!())
      .pipe(map(res => new Action().deserialize(res.data)));
  }

  public update(projectId: number, action: Partial<Action>): Observable<Action> {
    // currently only booking actions actively use serialize
    let actionData: Partial<Action> = action;
    if (action.type === ActionType.booking) {
      actionData = action.serialize!();
    }
    return this.http
      .put<{ data: Action }>(`projects/${projectId}/${this.endpoint}/${action.id}`, actionData)
      .pipe(map(res => new Action().deserialize(res.data)));
  }

  public delete(projectId: number, actionUuid: string): Observable<void> {
    return this.http.delete<undefined>(`projects/${projectId}/${this.endpoint}/${actionUuid}`);
  }

  public getIntegration(actionUuid: string) {
    return this.http.get(`integrations/${actionUuid}`);
  }

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected httpCache: HttpCacheService,
    protected storage: LocalStorageService
  ) {
    super(http, router, httpCache, storage);
  }
}
