<div class="position-relative drop-zone rounded overflow-hidden">
  <div *ngIf="imagePreviewSrc" [style.background-image]="'url(' + imagePreviewSrc! + ')'" class="image-preview position-absolute"></div>

  <ngx-file-drop
    (onFileDrop)="onFileDrop.emit($event)"
    *ngIf="!loading"
    [ngClass]="{ 'has-no-preview-image': !imagePreviewSrc }"
    [showBrowseBtn]="true"
    browseBtnClassName="mt-3 mat-flat-button mat-accent"
    class="drop-overlay"
    contentClassName="drop-zone-content"
    dropZoneClassName="drop-zone-inner rounded p-4"
    dropZoneLabel="Drop image here"
    multiple="false"
  >
  </ngx-file-drop>

  <button
    (click)="removeFile.emit()"
    [disabled]="deleting"
    [ngClass]="{ 'd-block': imagePreviewSrc, 'd-none': !imagePreviewSrc }"
    class="remove-btn position-absolute"
    color="warn"
    mat-flat-button
  >
    <i [ngClass]="{ 'fa-spin fa-spinner': deleting, 'fa-times': !deleting }" class="fas fa-times"></i>
  </button>

  <app-button-loading-indicator *ngIf="loading" class="position-absolute loading-indicator"> </app-button-loading-indicator>
</div>
