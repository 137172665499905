import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeService, UploadStatus } from 'src/app/services/api/methods/theme.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface UploadThemeDialogData {
  projectId: number;
}

@Component({
  selector: 'app-upload-theme',
  templateUrl: './upload-theme.component.html',
  styleUrls: ['./upload-theme.component.scss']
})
export class UploadThemeComponent implements OnInit {
  public form!: FormGroup;
  public error?: { data?: { message?: string } };
  public progress = 0;
  public status?: UploadStatus;

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      theme: ['']
    });
  }

  public onFileChange(event): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('theme')!.setValue(file);
    }
  }

  public onUpload(): void {
    const formData = new FormData();
    formData.append('file', this.form.get('theme')!.value);

    this.themeService.uploadTheme(this.data.projectId, formData).subscribe(
      res => {
        if (res) {
          this.status = res.status;
          if (res.status === 'progress') {
            this.progress = res.message;
          } else if (res.status === 'success') {
            this.dialogRef.close(res.message?.data);
          } else if (res.status === 'error') {
            this.error = res.message;
          }
        }
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.status = UploadStatus.error;
        this.error = err.error;
      }
    );
  }

  constructor(
    public dialogRef: MatDialogRef<UploadThemeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadThemeDialogData,
    private themeService: ThemeService,
    private formBuilder: FormBuilder
  ) {}
}
