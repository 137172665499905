import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Guest } from 'src/app/entities/events/guest.entity';
import { Audience } from 'src/app/entities/resources/audience.entity';
import { AudiencesService } from 'src/app/services/api/methods/audiences.service';

export interface AddGuestToAudienceData {
  guests: Guest[];
  projectId: number;
}

enum CheckedStatus {
  checked,
  indeterminate,
  notChecked
}

@Component({
  selector: 'app-add-guest-to-audience',
  templateUrl: './add-guest-to-audience.component.html',
  styleUrls: ['./add-guest-to-audience.component.scss']
})
export class AddGuestToAudienceComponent implements AfterViewInit {
  @Input('data') public data!: AddGuestToAudienceData;
  public rows: { audience: Audience; checkedStatus: CheckedStatus }[] = [];
  public isLoading = false;
  public CheckedStatus = CheckedStatus;
  public readonly headerHeight = 50;
  public readonly rowHeight = 50;
  @ViewChild('table', { static: true }) public table!: ElementRef;
  private readonly perPage = 2;
  private lastPage: number = 2;
  private currentPage: number = 1;

  public ngAfterViewInit(): void {
    this.onScroll(0);
  }

  public loadAudiences(pageNumber: number = 1, perPage: number = this.perPage, search?: string): void {
    this.isLoading = true;
    this.audiencesService.getAll(this.data.projectId, perPage, pageNumber, search).subscribe(res => {
      this.rows = [...this.rows, ...res.data.map(audience => ({ audience, checkedStatus: this.getCheckedStatus(audience) }))];
      this.lastPage = res.meta.last_page;
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  public getCheckedStatus(audience: Audience): CheckedStatus {
    const audienceIds = this.data.guests.map(guest => guest.audience_ids);
    console.log(this.data.guests);
    const mergedAudienceIds: number[] = ([] as number[]).concat(...audienceIds);

    if (mergedAudienceIds.filter(id => id === audience.id).length >= this.data.guests.length) {
      return CheckedStatus.checked;
    } else if (mergedAudienceIds.includes(audience.id!)) {
      return CheckedStatus.indeterminate;
    } else {
      return CheckedStatus.notChecked;
    }
  }

  public onScroll(offsetY: number, search: string = ''): void {
    // total height of all rows in the viewport
    const viewHeight = this.table.nativeElement.getBoundingClientRect().height - this.headerHeight;

    // check if we scrolled to the end of the viewport
    if (!this.isLoading && offsetY + viewHeight >= this.rows.length * this.rowHeight) {
      // total number of results to load
      let limit = this.perPage;

      // check if we haven't fetched any results yet
      if (this.rows.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        limit = Math.max(pageSize, this.perPage);
      }

      // only try to load if there are 0 items or the last hasn't been reached yet
      // if(this.currentPage !== this.lastPage || this.rows.length <= 0) {
      //   this.loadAudiences(this.rows.length / limit + 1, limit, search);
      // }

      // !! backend currently ignores perPage params for the audience list !! && this.currentPage !== this.lastPage
      if (this.currentPage <= this.lastPage) {
        this.loadAudiences(this.currentPage, 15, search);
        this.currentPage++;
      }
    }
  }

  public onActivate(event: { row: { audience: Audience; checkedStatus: CheckedStatus }; type: string }): void {
    const row = this.rows.find(row => row.audience.id === event.row.audience.id);
    if (!row) {
      return;
    }

    if (event.type === 'click') {
      if (event.row.checkedStatus === CheckedStatus.indeterminate || event.row.checkedStatus === CheckedStatus.notChecked) {
        row.checkedStatus = CheckedStatus.checked;
        this.data.guests.forEach(guest => guest.audience_ids.push(event.row.audience.id!));
        forkJoin(
          this.data.guests.map(guest => this.audiencesService.addGuestToAudience(this.data.projectId, event.row.audience.id!, guest.id!))
        ).subscribe(
          res => {},
          err => {
            this.toastr.error('Could not add Guest.', 'Error');
          }
        );
      } else {
        row.checkedStatus = CheckedStatus.notChecked;
        this.data.guests.forEach(guest => (guest.audience_ids = guest.audience_ids.filter(id => id !== event.row.audience.id)));
        forkJoin(
          this.data.guests.map(guest =>
            this.audiencesService.removeGuestFromAudience(this.data.projectId, event.row.audience.id!, guest.id!)
          )
        ).subscribe(
          res => {},
          err => {
            this.toastr.error('Could not remove Guest.', 'Error');
          }
        );
      }
    }
  }

  constructor(private audiencesService: AudiencesService, private toastr: ToastrService, private cdr: ChangeDetectorRef) {}
}
