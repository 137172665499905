import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-base-setting',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseSettingComponent {
  @Input() public twoColumns = true;
  @Input() @Required public name!: string;

  @Input() public defaultValue: any;
  @Input() public hasDefaultValue = false;
  @Input() @Required public ngModel!: string | null;
  @Output() public readonly ngModelChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  public update(value: string | undefined | null): void {
    this.ngModelChange.emit(value);
    this.cdr.markForCheck();
  }

  public switchToDefault(): void {
    this.confirmationDialogService
      .confirm('Use Default', 'Are you sure you want to use the default value of this field? This will delete the current value.')
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.update(null);
        this.cdr.markForCheck();
      });
  }
  constructor(protected confirmationDialogService: ConfirmationDialogService, protected cdr: ChangeDetectorRef) {}
}
