import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditLocaleSelectorComponent } from './project/resources/edit-locale-selector/edit-locale-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElementsModule } from './elements/elements.module';
import { FavoriteService } from '../services/favorite.service';

const SHARED_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ElementsModule,
  MatFormFieldModule,
  MatOptionModule,
  MatSelectModule
];

const SHARED_COMPONENTS = [
  EditLocaleSelectorComponent
];

const SHARED_PROVIDERS = [
  FavoriteService
];

@NgModule({
  declarations: [SHARED_COMPONENTS],
  imports: [SHARED_MODULES],
  exports: [SHARED_MODULES, SHARED_COMPONENTS],
  providers: [SHARED_PROVIDERS]
})
export class SharedModule {}
