import { Component, OnInit, ViewChild } from '@angular/core';
import { CheatSheetService } from './services/cheatsheet.service';
import { VersionCheckService } from './services/version-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  @ViewChild('cheatSheet', { static: true }) public cheatSheet;

  public ngOnInit(): void {
    this.cheatSheetService.init(this.cheatSheet);
    this.versionCheckService.initVersionCheck();
  }
  constructor(private cheatSheetService: CheatSheetService, private versionCheckService: VersionCheckService) {}
}
