import { Injectable } from '@angular/core';
import { ColumnDataSelection } from '../components/elements/table/table-column-settings/table-column-settings.component';
import { ToastrService } from 'ngx-toastr';

export interface Favorite {
  id?: number;
  label?: string;
  active?: boolean;
  data?: ColumnDataSelection[] | any[];
}

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  public getFavorites(key: string): Favorite[] {
    return JSON.parse(window.localStorage.getItem('_|' + key) || '[]') as Favorite[];
  }

  public removeFavorite(key: string, index: number): void {
    const allFavorites = this.getFavorites(key);
    allFavorites.splice(index, 1);
    window.localStorage.setItem('_|' + key, JSON.stringify(allFavorites));

    this.toastr.success('Favorite deleted', 'Success');
  }

  public addFavorite(key: string, favorite: Favorite, setActiveState: boolean = false): Favorite[] {
    let allFavorites = this.getFavorites(key);

    if (setActiveState) {
      allFavorites = allFavorites.map(favorite => {
        return { ...favorite, active: false };
      });
    }

    allFavorites.push(favorite);
    window.localStorage.setItem('_|' + key, JSON.stringify(allFavorites));

    this.toastr.success('Favorite saved', 'Success');

    return this.getFavorites(key);
  }

  public changeFavorite(key: string, updateFavorite: Favorite): Favorite[] {
    let allFavorites = this.getFavorites(key);

    allFavorites = allFavorites.map(favorite => {
      if (favorite.id == updateFavorite.id) {
        return updateFavorite;
      } else {
        return { ...favorite, active: false };
      }
    });

    window.localStorage.setItem('_|' + key, JSON.stringify(allFavorites));

    return this.getFavorites(key);
  }

  public selectFavoriteColumnByActiveState(key: string): Favorite | null {
    const allFavorites = this.getFavorites(key);
    return (allFavorites.find(favorite => favorite.active) as Favorite) || null;
  }

  constructor(private toastr: ToastrService) {}
}
