import { HttpErrorResponse } from '@angular/common/http';
import {FormControl, FormGroup} from '@angular/forms';
import {Form} from './resources/form.entity';

export interface FormServerErrors {
  [fieldName: string]: Record<string, FormErrorValue | FormServerErrors>;
}

export interface FormErrorValue {
  errors: string[];
}

/**
 * The 422 error
 */
export class UnprocessableEntityResponse extends HttpErrorResponse {
  public readonly error: {
    data: FormServerErrors;
  };

  public delegateToFormField(field: FormControl, dataKey: string): void {
    if(field) {
      field.markAsTouched();
      field.setErrors({
        serverError: this.error.data[dataKey]
      });
    } else {
      console.error('Could not assign error to Form Field', field, this.error.data);
    }
  }

  public delegateToForm(form: FormGroup, errorData?): void {
    errorData = errorData ? errorData : this.error.data;
    Object.keys(errorData).forEach(prop => {
      const formControl = form.get(prop);
      if (formControl && formControl instanceof FormControl) {
        formControl.markAsTouched();
        formControl.setErrors({
          serverError: errorData[prop]
        });
      } else if (formControl && formControl instanceof FormGroup) {
        this.delegateToForm(formControl as FormGroup, errorData[prop]);
      } else {
        console.error('could not show form error for unknown form control in form group', form, errorData[prop]);
      }
    });
  }

  constructor(init: HttpErrorResponse) {
    super({
      error: init.error,
      headers: init.headers,
      status: 422,
      statusText: init.statusText,
      url: init.url || undefined
    });
    if (init.status !== 422) {
      throw new Error('The given response has the wrong status code');
    }
    this.error = init.error;
  }
}
