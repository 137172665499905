import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/api/methods/authentication.service';

@Injectable()
export class IsLoggedOut implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  public canActivate() {
    if (this.authenticationService.isLoggedIn()) {
      const urlToGo = location.pathname + location.search;
      this.authenticationService.logout().subscribe(() => {
        this.router.navigateByUrl(urlToGo);
      });
      return false;
    } else {
      return true;
    }
  }
}
