import { Moment } from 'moment';
import { Deserializable } from '../deserializable.model';
import { Model } from '../model';
import { Audience } from '../resources/audience.entity';
import { HO_EMAIL_FIELD_NAME, HO_PASSWORD_FIELD_NAME } from '../resources/form-field.entity';
import { BookingDetails, MultiValueBookingData } from './booking-details.entity';
import { EventBooking } from './event-booking.entity';
import { Event } from './event.entity';
import { GuestCustomFieldsDefinition } from './guest-custom-fields-definition.entity';
import { GuestCustomFieldEntity } from './guest-custom-field.entity';
import { MeetingBooking } from '../meetings/meeting-booking.entity';

export class Guest extends Model implements Deserializable<Guest> {
  public readonly id?: number;
  public created_at?: Moment;
  public updated_at?: Moment;
  public salutation!: string | null;
  public first_name!: string | null;
  public last_name!: string | null;
  public company!: string | null;
  public phone!: string | null;
  public mobile_phone!: string | null;
  public street!: string | null;
  public house_number!: string | null;
  public zip!: string | null;
  public city!: string | null;
  public country!: string | null;
  public email!: string | null;
  public email_verified_at?: string | null = null;
  public email_verified: boolean = false;
  public password!: string | null;
  public custom_fields: string[] = [];
  public audience_ids: number[] = [];
  public audiences: Audience[] = [];
  public event_ids: number[] = [];
  public events?: Event[];
  public event_booking?: EventBooking;
  public meeting_booking?: MeetingBooking;
  public profile_image_url!: string | null;
  public profile_image_submit_url!: string;
  public profile_image_uuid!: string | null;
  public fields!: GuestCustomFieldEntity[];
  public custom_first_name_field: GuestCustomFieldEntity | null = null;
  public custom_last_name_field: GuestCustomFieldEntity | null = null;
  public last_activity_at?: string;
  public campaign_scores?: { title: string; user_score: number; user_simple_max_score: number; user_max_score: number }[];

  /** Iterates through booking details and returns a simple key value pairs */
  private static reduceBookingDetails(bookingDetails: BookingDetails): { [key: string]: string } {
    const flatBookingDetails = {};
    Object.entries(bookingDetails).forEach(([key, values]) => {
      if (key === HO_EMAIL_FIELD_NAME || key === HO_PASSWORD_FIELD_NAME) {
        return;
      }
      if (key === 'files') {
        return;
      }
      // tslint:disable-next-line:prefer-conditional-expression
      if (Array.isArray(values?.data) && values?.data.every(e => typeof e !== 'string')) {
        flatBookingDetails[key] = (values.data as MultiValueBookingData[]).map((e: MultiValueBookingData) => e.value);
      } else {
        flatBookingDetails[key] = values?.data || (values as unknown);
      }
    });
    return flatBookingDetails;
  }

  /** Filters object to specified keys */
  private static reduceObjectToKeys(object: { [key: string]: any }, keys: string[] = []): { [key: string]: any } {
    if (keys.length === 0) {
      return object;
    }
    return Object.keys(object)
      .filter(key => keys.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: object[key]
        };
      }, {});
  }

  public serialize(): any {
    const serialized = super.serialize();
    if (!this.custom_fields) {
      serialized.custom_fields = [];
    }
    return serialized;
  }

  public export(keys?: string[]): { [key: string]: any } {
    let flatBookingDetails = {};
    let attachments = {};
    if (this.event_booking && this.event_booking.booking_details) {
      flatBookingDetails = Guest.reduceBookingDetails(this.event_booking.booking_details);
      attachments = this.event_booking.attachments;
    }

    // used to display legacy custom data
    let flatCustomFields = {};
    if (this.custom_fields) {
      // @ts-ignore
      flatCustomFields = Guest.reduceBookingDetails(this.custom_fields);
    }
    return Guest.reduceObjectToKeys({ ...this, ...flatBookingDetails, attachments, ...flatCustomFields }, keys);
  }
}
