import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { map } from 'rxjs/operators';
import { NotificationType } from 'src/app/entities/notifications/notification-type.enum';
import { Notification } from 'src/app/entities/notifications/notification.entity';
import { AuthenticationService } from 'src/app/services/api/methods/authentication.service';
import { NotificationsService } from 'src/app/services/api/methods/notifications.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { environment } from 'src/environments/environment';
import { Project } from '../../../entities/project.entity';
import { TeamEntity } from '../../../entities/team/team.entity';
import { User } from '../../../entities/user.entity';
import { TeamsService } from '../../../services/api/methods/teams.service';
import { MembershipRole } from '../../../entities/membership.entity';
import { groupBy } from '../../../utils/group-by';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state('closed', style({ height: '0', overflow: 'hidden', opacity: '0' })),
      state('open', style({ overflow: 'hidden', opacity: '1' })),
      transition('* => *', animate('300ms'))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  public project!: Project;

  public activeThemeId: number | null = null;
  public currentUser?: User;
  public open = true;
  public environment = environment;
  public NotificationType = NotificationType;
  public notificationsByEvent: { [type: string]: Notification[] } = {};
  public membershipRole: MembershipRole | undefined;
  public MembershipRoles = MembershipRole;
  public isAdmin = false;

  public teams?: TeamEntity[];

  public collapsed: {
    all: boolean;
    pages: boolean;
    events: boolean;
    resources: boolean;
    profile: boolean;
    teams: boolean;
    notifications: boolean;
    admin: boolean;
    sales: boolean;
  } = {
    all: false,
    sales: false,
    pages: false,
    events: false,
    resources: false,
    profile: false,
    teams: false,
    notifications: false,
    admin: true
  };

  public ngOnInit(): void {
    this.sidenavService.opened.subscribe(open => (this.open = open));
    this.route.data.subscribe((data: Data) => {
      this.project = data.project;
      this.activeThemeId = this.project?.active_theme_id;
      if (data.project) {
        this.teamsService.getMyMembership(data.project.team).subscribe(membership => {
          this.membershipRole = membership.role;
        });
      }
    });

    this.authenticationService.getMe().subscribe((me: User) => {
      this.currentUser = me;
      this.isAdmin = me.is_admin;
    });

    this.collapsed = { ...this.collapsed, ...this.storage.retrieve('sidebar-state') };

    this.teamsService.getAll().subscribe(res => (this.teams = res.data));

    this.notificationsService.unreadNotifications$.pipe(map((res) => {
      return groupBy(res, 'event')
    })).subscribe(res => {
      this.notificationsByEvent = res;
    });
  }

  public toggleCollapsed(what: string): void {
    this.collapsed[what] = !this.collapsed[what];
    this.storage.store('sidebar-state', this.collapsed);
  }

  public logout(): void {
    this.authenticationService.logout().subscribe(() => {
      this.router.navigateByUrl('/login');
    });
  }

  public clearNotificationsForEvent(type: NotificationType): void {
    let notificationsToClear = this.notificationsByEvent[type];
    if (!notificationsToClear) {
      return;
    }
    this.notificationsService
      .markNotificationsAsRead(
        this.project.id!,
        notificationsToClear.map(n => n.id!)
      )
      .subscribe(() => {
        notificationsToClear = [];
      });
  }

  public onLoggedout(): void {
    this.authenticationService.logout().subscribe();
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private teamsService: TeamsService,
    private storage: LocalStorageService,
    private sidenavService: SidenavService,
    private notificationsService: NotificationsService
  ) {}
}
