import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Project } from '../../entities/project.entity';
import { ProjectService } from '../../services/api/methods/project.service';

@Injectable({ providedIn: 'root' })
export class ProjectResolver implements Resolve<Project> {
  private project: BehaviorSubject<Project | null> = new BehaviorSubject<Project | null>(null);
  public project$: Observable<Project | null> = this.project.asObservable();

  public resolve(route: ActivatedRouteSnapshot): Observable<Project> {
    return this.projectService.getProject(+route.params.project).pipe(
      tap((project: Project) => {
        window.localStorage.setItem('_|default_language', project.meta?.language?.default || '');
        this.project.next(project);
        this.storage.store('last_team_id', project.team.id);
      })
    );
  }

  public removeProject(): void {
    this.project.next(null);
  }

  constructor(private projectService: ProjectService, private storage: LocalStorageService) {}
}
