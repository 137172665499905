import { EventEmitter, Injectable } from '@angular/core';
import EncryptedChannel from 'pusher-js/types/src/core/channels/encrypted_channel';
import Pusher from 'pusher-js/with-encryption';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './api/methods/authentication.service';
import { Guest } from '../entities/events/guest.entity';
import { Event } from '../entities/events/event.entity';
import { EventBooking } from '../entities/events/event-booking.entity';
import { FormSubmission } from '../entities/resources/form-submission.entity';
import { Project } from '../entities/project.entity';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationType } from '../entities/notifications/notification-type.enum';
import { Notification } from '../entities/notifications/notification.entity';
import { Channel } from 'pusher-js';

export interface ProjectEventData {
  guest?: Guest;
  event?: Event;
  event_booking?: EventBooking;
  form_submission?: FormSubmission;
}

export interface ProjectEvent {
  type: NotificationType;
  data: ProjectEventData;
  id: string;
  project_id: number;
}

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  public pusher: Pusher;
  public channel?: Channel;
  private events: EventEmitter<Notification> = new EventEmitter<Notification>();

  public projectEvents(project: Project): Observable<Notification> {
    return this.events.pipe(filter(event => event.project_id === project.id));
  }

  constructor(
    private authService: AuthenticationService // dependency must be registered in service.module.ts
  ) {
    this.pusher = new Pusher(environment.pusher.public_key, {
      cluster: 'eu',
      forceTLS: true,
      enableStats: true,
      authEndpoint: environment.api.endpoint + 'broadcasting/auth',
      auth: {
        headers: {
          authorization: 'Bearer ' + this.authService.getToken()
        }
      }
    });
    this.authService.getMe().subscribe(user => {
      this.channel = this.pusher.subscribe(`private-encrypted-user.${user.id}`);
      this.channel!.bind_global((pusherEventName: string, eventData: Partial<ProjectEvent>) => {
        if (Object.values(NotificationType).includes(eventData.type!)) {
          // todo the notification is not complete, we need to retrieve the object from the api

          this.events.emit(eventData as Notification);
        } else {
          if (!pusherEventName.startsWith('pusher:')) {
            console.warn('pusher event not handled by pusher service', pusherEventName, eventData);
          }
        }
      });
    });
  }
}
