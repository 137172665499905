import { HttpRequest, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { parseCacheControl } from '../../utils/parse-cache-control';

@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {
  public cacheDeleted: EventEmitter<void> = new EventEmitter();
  private cache = {};

  public get(req: HttpRequest<any>): HttpResponse<any> | null {
    if (req.method !== 'GET') {
      // Delete cache of same url when method is not GET (e.g. update happened on the resource)
      // this.cache[req.method + req.urlWithParams] = null;
      this.deleteCache();
      return null;
    }
    return this.cache[req.method + req.urlWithParams];
  }

  public put(req: HttpRequest<any>, resp: HttpResponse<any | null>): void {
    if (req.method !== 'GET') {
      return;
    }
    // check if caching is allowed! and how long
    if (resp.headers.get('Cache-Control')) {
      const headers = parseCacheControl(resp.headers.get('Cache-Control'));

      // cache only if max age header is set
      if (headers && typeof headers['max-age'] === 'number') {
        this.cache[req.method + req.urlWithParams] = resp;
        setTimeout(() => {
          delete this.cache[req.method + req.urlWithParams];
        }, 1000 * headers['max-age']);
      }
    }

    // Cache-Control: must-revalidate
    // Cache-Control: no-cache
    // Cache-Control: no-store
    // Cache-Control: no-transform
    // Cache-Control: public
    // Cache-Control: private
    // Cache-Control: proxy-revalidate
    // Cache-Control: max-age=<seconds>
    // Cache-Control: s-maxage=<seconds>
  }

  public deleteCache() {
    this.cacheDeleted.emit();
    this.cache = {};
  }
}
