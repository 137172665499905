import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertNotificationsService } from './alert-notifications.service';
import { environment } from '../../environments/environment';
import { timer } from 'rxjs';
import { Router } from '@angular/router';

declare const COMMIT_REF: string;

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  private updateHandled = false;

  private url = `${window.location.protocol}//${window.location.host}/version_check`;
  private alertSent = false;

  public initVersionCheck(frequency = 1000 * 60): void {
    const source = timer(frequency, frequency);
    source.subscribe(val => {
      this.checkVersion();
    });
  }

  private checkVersion(): void {
    if (environment.envName === 'local' || this.updateHandled) {
      return;
    }

    // timestamp these requests to invalidate caches
    this.http
      .get<{
        version: string;
        version_update_method: 'notice' | 'none' | 'next_route' | 'force';
      }>(this.url + '?t=' + new Date().getTime())
      .subscribe(
        response => {
          const hashChanged = COMMIT_REF !== response.version;

          const version_update_method = response.version_update_method;

          // If new version, do something
          if (hashChanged) {
            this.updateHandled = true;
            switch (version_update_method) {
              case 'force':
                window.location.reload();
                break;

              case 'none':
                return;
              case 'notice':
                this.alertService.warn(
                  'There is a new version of our dashboard available. Please refresh the page now.',
                  true,
                  'Seite neu laden',
                  () => {
                    window.location.reload();
                  }
                );
                break;
              case 'next_route':
              default:
                this.router.events.subscribe(() => {
                  window.location.reload();
                });

                break;
            }
          }
        },
        e => {
          console.error(e);
        }
      );
  }

  constructor(private http: HttpClient, private alertService: AlertNotificationsService, private router: Router) {}
}
