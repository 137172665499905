import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule } from 'saturn-datepicker';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MaterialModule } from 'src/app/material.module';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { BadgeComponent } from './badge/badge.component';
import { LoadingIndicatorComponent } from './button-loading-indicator/button-loading-indicator.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DateTimeRangePickerComponent } from './date-time-range-picker/date-time-range-picker.component';
import { TimeFooterComponent } from './date-time-range-picker/time-footer/time-footer.component';
import { DragHandleComponent } from './drag-handle/drag-handle.component';
import { EditorComponent } from './editor/editor.component';
import { HeaderComponent } from './header/header.component';
import { PageLoadingIndicatorComponent } from './page-loading-indicator/page-loading-indicator.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SettingComponent } from './setting/setting.component';
import { ActionSettingComponent } from './setting/settings/action-setting/action-setting.component';
import { BaseSettingComponent } from './setting/settings/base-setting.component';
import { BooleanSettingComponent } from './setting/settings/boolean-setting/boolean-setting.component';
import { ColorSettingComponent } from './setting/settings/color-setting/color-setting.component';
import { EventSettingComponent } from './setting/settings/event-setting/event-setting.component';
import { HtmlSettingComponent } from './setting/settings/html-setting/html-setting.component';
import { ImageSettingComponent } from './setting/settings/image-setting/image-setting.component';
import { RepeaterSettingComponent } from './setting/settings/repeater-setting/repeater-setting.component';
import { SelectSettingComponent } from './setting/settings/select-setting/select-setting.component';
import { TextAreaSettingComponent } from './setting/settings/text-area-setting/text-area-setting.component';
import { TextSettingComponent } from './setting/settings/text-setting/text-setting.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TableToolbarComponent } from './table-toolbar/table-toolbar.component';
import { TableComponent } from './table/table.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UploadThemeComponent } from './upload-theme/upload-theme.component';
import { UploadComponent } from './upload/upload.component';
import { TemplateSettingTypeIconComponent } from './template-setting-type-icon/template-setting-type-icon.component';
import { FormControlErrorComponent } from './form-control-error/form-control-error.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { QuillModule } from 'ngx-quill';
import { MatQuillComponent } from './mat-quill/mat-quill.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { GuestInfoComponent } from './guest-info/guest-info.component';
import { AddGuestToAudienceComponent } from './add-guest-to-audience/add-guest-to-audience.component';
import { VirtualScrollListComponent } from './virtual-scroll-list/virtual-scroll-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SalesTimelineComponent } from './sales-timeline/sales-timeline.component';
import { SalesProgressBarComponent } from './sales-progress-bar/sales-progress-bar.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'llll'
  },
  display: {
    dateInput: 'llll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const components = [
  HeaderComponent,
  SidebarComponent,
  LoadingIndicatorComponent,
  ConfirmationDialogComponent,
  UploadComponent,
  EditorComponent,
  BaseSettingComponent,
  TextSettingComponent,
  TextAreaSettingComponent,
  BooleanSettingComponent,
  ImageSettingComponent,
  SelectSettingComponent,
  HtmlSettingComponent,
  ColorSettingComponent,
  RepeaterSettingComponent,
  HtmlSettingComponent,
  SettingComponent,
  ToolbarComponent,
  SearchInputComponent,
  TableToolbarComponent,
  PageLoadingIndicatorComponent,
  BadgeComponent,
  ActionSettingComponent,
  EventSettingComponent,
  TableComponent,
  DateTimeRangePickerComponent,
  TimeFooterComponent,
  DragHandleComponent,
  UploadThemeComponent,
  FormControlErrorComponent,
  TemplateSettingTypeIconComponent,
  DropzoneComponent,
  MatQuillComponent,
  GuestInfoComponent,
  AddGuestToAudienceComponent,
  VirtualScrollListComponent,
  SalesTimelineComponent,
  SalesProgressBarComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxChartsModule,
    NgxFileDropModule,
    ColorPickerModule,
    SharedPipesModule,
    UiSwitchModule,
    DragDropModule,
    MaterialModule,
    NgbCollapseModule,
    NgxDatatableModule,
    DirectivesModule,
    SatDatepickerModule,
    QuillModule.forRoot(),
    ClipboardModule,
    ScrollingModule
  ],
  //entryComponents: [ConfirmationDialogComponent, AddRepeaterSettingRowComponent, TimeFooterComponent, UploadThemeComponent],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    // {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  declarations: [...components],
  exports: [...components]
})
export class ElementsModule {}
