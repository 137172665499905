<div mat-dialog-content>

  <div class="d-flex justify-content-between align-items-center w-100 mb-2"
       *ngFor="let favorite of favorites; let index = index">
    <div class="d-flex align-items-center ml-n2" (click)="selectFavorite(favorite)">
      <button class="w-100 rounded-0" mat-icon-button>
        <mat-icon [class.selected-favorite]="favorite.id != selectedFavorite?.id" color="primary">bookmark</mat-icon>
        <span class="w-100">{{favorite.label}}</span>
      </button>
    </div>

    <button mat-icon-button
            class="mr-n2"
            (click)="removeFavorite(index)">
      <mat-icon color="warn">delete</mat-icon>
    </button>
  </div>

  <div class="d-flex">
    <input [(ngModel)]="newFavoriteName" matInput placeholder="New favorite name" type="text"/>
    <button class="ml-2" color="accent" mat-flat-button [disabled]="!newFavoriteName" (click)="saveFavorite()">
      Save
    </button>
  </div>

  <hr>

  <mat-selection-list #columnList
                      [ngModel]="filteredColumns"
                      (selectionChange)="selectListItem()"
                      cdkDropList
                      class="drag-list"
                      (cdkDropListDropped)="dropListItem($event)">
    <mat-list-option
            *ngFor="let column of columns"
            [value]="column"
            [selected]="column.selected"
            cdkDrag
            class="drag-list-item">
      <div class="d-flex align-items-center"><mat-icon style="cursor: grab">reorder</mat-icon> <span class="mx-1 text-nowrap">{{column.label}}</span></div>
    </mat-list-option>
  </mat-selection-list>

</div>
<div mat-dialog-actions class="justify-content-between">
  <button (click)="dialogRef.close()" mat-button>Cancel</button>
  <button (click)="appendFavorite()" mat-button>Done</button>
</div>
