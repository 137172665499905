import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {ActionType} from 'src/app/entities/actions/action-type.enum';
import {Action} from 'src/app/entities/actions/action.model';
import {Event} from 'src/app/entities/events/event.entity';
import {Project} from 'src/app/entities/project.entity';
import {ActionsService} from 'src/app/services/api/methods/actions.service';
import {EventsService} from 'src/app/services/api/methods/events.service';
import {FormsService} from 'src/app/services/api/methods/forms.service';
import {ConfirmationDialogService} from 'src/app/services/confirmation-dialog.service';
import {BaseSettingComponent} from '../base-setting.component';
import {Required} from 'src/app/utils/required-input';
import {PagesService} from '../../../../../services/api/methods/pages.service';
import {Page} from '../../../../../entities/pages/page.entity';
import {FormControl} from '@angular/forms';
import {UnprocessableEntityResponse} from '../../../../../entities/unprocessable-entity-response';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-action-setting',
  templateUrl: './action-setting.component.html',
  styleUrls: ['./action-setting.component.scss']
})
export class ActionSettingComponent extends BaseSettingComponent implements OnInit {
  @Input() @Required public actionType!: ActionType;
  @Input() @Required public project!: Project;

  public ActionType = ActionType;

  public selectedEvent?: Event;
  public events: Event[] = [];

  public selectedAction?: Action;
  public bookingActions?: Action[] = [];

  public questionForms: { title: string; id: number }[] = [];
  public registerForms: { title: string; id: number }[] = [];

  public bookingSourceLink?: string;

  public isLoginAction: boolean = false;
  public pages: Page[] = [];
  public successPageIdControl: FormControl = new FormControl(null, []);

  public eventChanged(ev): void {
    this.selectedEvent = ev;
    this.bookingActions = this.selectedEvent?.booking_actions;
    this.selectedAction = undefined;
    this.bookingSourceLink = undefined;
  }

  public audienceChanged(audience): void {
    this.selectedAction = this.bookingActions?.find(action => action.id === audience);
    this.ngModelChange.emit(audience);
    this.bookingSourceLink = undefined;
  }

  public bookingSourceChanged(bookingSource): void {
    let currentPageLink = localStorage.getItem('current_page');
    if (currentPageLink) {
      this.bookingSourceLink = currentPageLink + '?bsrc=' + bookingSource;
    } else {
      this.bookingSourceLink = 'Cannot build external url - check domain config!';
    }
  }

  public ngOnInit(): void {
    let getActionRequest = this.actionsService.get(this.project.id, this.ngModel!).pipe(catchError(() => of(null)));
    let getAllEventsRequest = this.eventsService.getAll(this.project.id, 100).pipe(
      map(
        res => res.data.filter(event => event.booking_actions && event.booking_actions.length > 0),
        catchError(() => of(null))
      )
    );
    let getAllFormSubmissionsRequest = this.formsService.getAll(this.project.id, 100, 1, '', [ActionType.formSubmission]).pipe(
      map(res => res.data.filter(form => form.actions && form.actions.length > 0)),
      catchError(() => of(null))
    );
    let getAllRegistrationFormsRequest = this.formsService.getAll(this.project.id, 100, 1, '', [ActionType.registration]).pipe(
      map(res => res.data.filter(form => form.actions && form.actions.length > 0)),
      catchError(() => of(null))
    );

    //TODO: should be improved at some point...
    if (this.ngModel === null) {
      forkJoin([getAllEventsRequest, getAllFormSubmissionsRequest, getAllRegistrationFormsRequest]).subscribe(
        ([events, questionForms, registerForms]: any) => {
          this.handleSubscribe(null, events, questionForms, registerForms);
        }
      );
    } else {
      forkJoin([getActionRequest, getAllEventsRequest, getAllFormSubmissionsRequest, getAllRegistrationFormsRequest]).subscribe(
        ([action, events, questionForms, registerForms]: any) => {
          this.handleSubscribe(action, events, questionForms, registerForms);
        }
      );
    }

    if (this.ngModel === null) {
      this.update(this.defaultValue);
    }
  }

  private handleSubscribe(action, events, questionForms, registerForms): void {
    this.selectedAction = action;
    if(this.selectedAction?.type === ActionType.login) {
      this.isLoginAction = true;
      this.loadPages();
      if(this.selectedAction.payload?.success_page_id && !isNaN(Number(this.selectedAction.payload.success_page_id))) {
        this.successPageIdControl.setValue(this.selectedAction.payload.success_page_id);
      }
    }
    this.selectedEvent = events.find(event => event.id === action?.actionable_id);
    this.bookingActions = this.selectedEvent?.booking_actions;
    this.events = events;
    this.questionForms = questionForms.map(form => ({ title: form.title, id: form.actions[0]?.id }));
    this.registerForms = registerForms.map(form => ({ title: form.title, id: form.actions[0]?.id }));
    this.cdr.markForCheck();
  }

  private loadPages() {
    this.pagesService.getAll(this.project, 100, 1, '', undefined, '', this.project.meta?.language?.default).pipe(take(1)).subscribe(
      (res) => {
        this.pages = res.data;
        this.cdr.markForCheck();
      }
    );
  }

  public saveLoginAction() {
    if(this.selectedAction && this.selectedAction?.type === ActionType.login && this.successPageIdControl.value && this.isLoginAction) {
      this.selectedAction.payload = {...this.selectedAction.payload, ...{
          "type": "login",
          "success_page_id": this.successPageIdControl.value,
        }
      };
      this.actionsService.update(this.project.id, this.selectedAction).pipe(take(1)).subscribe(
          (res) => {
            this.toastr.success('Saved default success login page', 'Success');
            this.selectedAction = res;
            this.cdr.markForCheck();
          },
          (error) => {
            if (error instanceof UnprocessableEntityResponse) {
              return error.delegateToFormField(this.successPageIdControl, 'payload');
            }
            this.toastr.error('Unable to save default success login page', 'Error');
            console.error(error);
            this.cdr.markForCheck();
          }
      );
    }
  }

  constructor(
    private eventsService: EventsService,
    private formsService: FormsService,
    private actionsService: ActionsService,
    private pagesService: PagesService,
    private toastr: ToastrService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected cdr: ChangeDetectorRef
  ) {
    super(confirmationDialogService, cdr);
  }
}
