import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ImageResponse } from 'src/app/entities/image-response.entity';
import { SignedUploadRequestResponse } from 'src/app/entities/single-upload-request-response.entity';
import { Project } from '../../../entities/project.entity';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService extends ApiService {
  public uploadFileToCloudinary(project: Project, file): Observable<ImageResponse> {
    return this.http.get<SignedUploadRequestResponse>(`projects/${project.id}/image-tag`).pipe(
      switchMap(res => {
        const formData = new FormData();
        // tslint:disable-next-line:forin
        Object.keys(res['data-form-data']).forEach((value, index, array) => {
          formData.append(value, res['data-form-data'][value]);
        });

        formData.append('file', file);

        return this.http.post<ImageResponse>(res['data-url'], formData);
      })
    );
  }

  public uploadFilesToUrl(url: string, files: File[]): Observable<{ files: string[] }> {
    const data = new FormData();
    files.forEach(file => {
      data.append('files[]', file);
    });

    return this.http.post<{ files: string[] }>(url, data);
  }

  public deleteFileOnCloudinary(project: Project, url: string): Observable<boolean> {
    // https://stackoverflow.com/questions/511761/js-function-to-get-filename-from-url
    const fileName = url.split('/').pop()?.split('#')[0].split('?')[0];
    const publicId = fileName?.split('.')[0].replace('C:\\fakepath\\', '');

    return this.http.delete<boolean>(`projects/${project.id}/image-tag/${publicId}`);
  }
}
