import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { NgxHotkeysModule } from '@balticcode/ngx-hotkeys';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ToastrModule } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { ElementsModule } from './components/elements/elements.module';
import { DirectivesModule } from './directives/directives.module';
import { CanDeactivateGuard } from './guard/can-deactivate.guard';
import { IsLoggedIn } from './guard/is-logged-in.guard';
import { IsLoggedOut } from './guard/is-logged-out.guard';
import { MaterialModule } from './material.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { ServiceModule } from './services/service.module';
import { environment } from '../environments/environment';
import { IsMembershipRole } from './guard/is-membership-role.guard';
import { IsAdmin } from './guard/is-admin.guard';
import { SharedModule } from './components/shared.module';

declare const COMMIT_REF: string;
declare const BUGSNAG_API_KEY: string;
declare const BUGSNAG_RELEASE_STAGE: string;

export const jwtOptionsFactory = (storage: LocalStorageService) => ({
  tokenGetter: () => {
    return storage.retrieve('access_token');
  }
});

// configure Bugsnag asap
if (environment.envName !== 'local') {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    releaseStage: BUGSNAG_RELEASE_STAGE,
    enabledReleaseStages: ['production', 'stage'],
    appVersion: COMMIT_REF,
    redactedKeys: [
      'access_token', // exact match: "access_token"
      /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
      /^cc_/, // prefix match: "cc_number" "cc_cvv" "cc_expiry"
      /^card_/, // prefix match: "card_last_four
      /secret/, // anything *secret*
      /key/ // anything *secret*
    ]
  });
}

@NgModule({
  imports: [
    SharedModule,
    MaterialModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always'
    }),
    CommonModule,
    ServiceModule,
    BrowserModule,
    FormsModule,
    ElementsModule,
    DirectivesModule,
    SharedPipesModule,
    HttpClientModule,
    NgxHotkeysModule.forRoot({
      cheatSheetCloseEsc: true,
      disableCheatSheet: true
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressAnimation: 'increasing',
      easeTime: 100,
      onActivateTick: true
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [LocalStorageService]
      }
    })
  ],
  declarations: [AppComponent],
  providers: [
    IsLoggedIn,
    IsAdmin,
    IsMembershipRole,
    CanDeactivateGuard,
    IsLoggedOut,
    {
      provide: ErrorHandler,
      useFactory: (): ErrorHandler => {
        return environment.envName === 'local' ? new ErrorHandler() : new BugsnagErrorHandler();
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
