import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

import { LocalStorageService } from 'ngx-webstorage';
import { catchError, filter, flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from './methods/authentication.service';
import { UnprocessableEntityResponse } from '../../entities/unprocessable-entity-response';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not modify requests to frontend server
    if (req.url.includes(`${window.location.protocol}//${window.location.host}`)) {
      return next.handle(req);
    }

    // do not modify requests for translation files
    if (req.url.includes('i18n')) {
      return next.handle(req);
    }

    // do not modify requests for domains
    if (req.url.startsWith('http') && !req.url.includes(environment.api.endpoint)) {
      return next.handle(req);
    }

    if (!req.url.includes('login')) {
      const token = this.authService.getToken();
      if (token) {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + token)
        });
      }
    }

    if (!req.url.includes(environment.api.endpoint)) {
      const url = environment.api.endpoint;
      req = req.clone({
        url: url + req.url
      });
    }

    return of(this.authService.tokenIsRefreshing).pipe(
      tap(isRefreshing => {
        if (isRefreshing.getValue()) {
          console.log('Delaying request due to token refreshing in progress ');
        }
      }),
      filter(isRefreshing => {
        if (req.url.endsWith('/refresh')) {
          return true;
        }
        return !isRefreshing.getValue();
      }),
      take(1),
      switchMap(() => next.handle(req)),
      catchError((error, caught) => {
        console.debug('failed http request with status', error.status);
        /*if (error.status === 400) {
          // logout users, redirect to login page
          this.authService.logout();
          this.router.navigate(['/login']);
          return throwError(error);
        }*/

        // If unauthorized and not accessing login or refresh endpoint, try to refresh token and add refreshed token
        // Actually will never work since you can only refresh a token when your logged in
        if (error.status === 401 && !req.url.endsWith('/login') && !req.url.endsWith('/refresh')) {
          return this.authService.refreshToken().pipe(
            flatMap(t => {
              const authRequestWithRefreshedToken = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this.authService.getToken())
              });
              return next.handle(authRequestWithRefreshedToken);
            })
          );
        }

        // return NEVER blocks any further request
        if (error.status === 422) {
          throw new UnprocessableEntityResponse(error);
        }
        if (error instanceof HttpErrorResponse && error.status === 400) {
          let message = 'Faulty http request';
          if (error.error?.data?.message) {
            message = error.error.data.message;
          }
          if (error.error?.message) {
            message = error.error.message;
          }
          this.toastr.error(message, 'Error');
          return NEVER;
        }

        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.toastr.error('Resource not found', 'Error');
          return NEVER;
        }

        if (error instanceof HttpErrorResponse && error.status === 401 && !req.url.endsWith('/login')) {
          let message = 'Unauthorized - logging out';

          if (error.error.message === 'email_not_verified') {
            message = 'Email verification missing - logging out';
          }

          this.toastr.error(message, 'Error');
          this.storage.clear('last_team_id');
          this.authService.logout();
          this.router.navigateByUrl('/login');
          return next.handle(req);
        }

        if (error instanceof HttpErrorResponse && error.status === 403) {
          let message = 'Access denied';
          if (error.error.message) {
            message = error.error.message;
          }
          this.toastr.error(message, 'Client error');
          return NEVER;
        }

        if (error instanceof HttpErrorResponse && (error.status === 423 || error.status === 428)) {
          this.toastr.warning(error.error.data ? error.error.data.message : error.error.message, 'Server Message');
          return NEVER;
        }

        if (error instanceof HttpErrorResponse && error.status === 412) {
          throw error;
        }

        if (error instanceof HttpErrorResponse && error.status !== 401) {
          this.toastr.error('Failed request', 'Server Error');
          console.error(error);
          return NEVER;
        }

        // return all others errors
        console.log('other error');

        throw error;
      }),
      map(response => response as any)
    );
  }

  constructor(
    private storage: LocalStorageService, // dependency must be registered in service.module.ts
    private authService: AuthenticationService, // dependency must be registered in service.module.ts,
    private toastr: ToastrService,
    private router: Router
  ) {}
}
