<span
  [ngClass]="{
    'bg-danger': color === 'danger',
    'bg-success': color === 'success',
    'bg-primary': color === 'primary',
    'bg-info': color === 'info',
    'bg-secondary': color === 'accent'
  }"
  class="ho-badge"
>
  <ng-content></ng-content>
</span>
