import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { Project } from 'src/app/entities/project.entity';
import { TableSort } from 'src/app/entities/table-sort.entity';
import { SortsAdapter } from 'src/app/utils/sorts-adapter';
import { BillingAddress } from '../../../entities/project-settings/billing-address.entity';
import { ApiService } from '../api.service';
import { HttpCacheService } from '../http-cache.service';
import { ProjectMeta } from '../../../entities/project-meta.entity';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends ApiService {
  private endpoint = 'projects';

  private $allProjects?: Observable<PaginatedResponse<Project>>;

  /**
   * @deprecated
   */
  public getAll(
    perPage: number = 100,
    page: number = 1,
    search: string = '',
    fromState: boolean = true,
    sorts: TableSort[] = []
  ): Observable<PaginatedResponse<Project>> {
    if (this.$allProjects && fromState) {
      return this.$allProjects;
    }
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts)
    };
    this.$allProjects = this.http
      .get<PaginatedResponse<Project>>(this.endpoint, { params })
      .pipe(
        // catchError(this.handleError('getAll', null)),
        shareReplay()
      );

    return this.$allProjects;
  }

  public getAllByTeam(
    teamId: string,
    perPage: number = 100,
    page: number = 1,
    search: string = '',
    fromState: boolean = true,
    sorts: TableSort[] = []
  ): Observable<PaginatedResponse<Project>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts)
    };

    return this.http.get<PaginatedResponse<Project>>('teams/' + teamId + '/' + this.endpoint, { params });
  }

  public getProject(id: number): Observable<Project> {
    return this.http.get<{ data: Project; meta: ProjectMeta }>(this.endpoint + '/' + id).pipe(
      map(res => {
        return { ...res.data, meta: res.meta };
      }),
      shareReplay()
    );
  }

  public update(project: Partial<Project>): Observable<Project> {
    this.$allProjects = undefined;
    return this.http.put<{ data: Project; meta: ProjectMeta }>(`${this.endpoint}/${project.id}`, project).pipe(
      map(res => {
        return { ...res.data, meta: res.meta };
      })
    );
  }

  public create(teamId: string, project: Partial<Project>): Observable<Project> {
    this.$allProjects = undefined;
    return this.http.post<{ data: Project; meta: ProjectMeta }>('teams/' + teamId + '/projects', project).pipe(
      map(res => {
        return { ...res.data, meta: res.meta };
      })
    );
  }

  public delete(project: Project): Observable<void> {
    this.$allProjects = undefined;
    return this.http.delete<undefined>(`${this.endpoint}/${project.id}`);
  }

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected httpCache: HttpCacheService,
    protected storage: LocalStorageService
  ) {
    super(http, router, httpCache, storage);
    this.httpCache.cacheDeleted.subscribe(() => (this.$allProjects = undefined));
  }
}
