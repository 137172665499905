<div class="w-100" [ngSwitch]="actionType">
  <div *ngSwitchCase="ActionType.booking">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Event</mat-label>
      <mat-select (valueChange)="eventChanged($event)" [value]="selectedEvent">
        <mat-option *ngFor="let option of events" [value]="option"
          >{{ option.title }} ({{ option.publish_status }} | {{ option.id }})</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Audience</mat-label>
      <mat-select (valueChange)="audienceChanged($event)" [disabled]="!selectedEvent" [value]="selectedAction?.id">
        <mat-option *ngFor="let bookingAction of bookingActions" [value]="bookingAction.id">
          {{ bookingAction?.payload?.audience?.title }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <div *ngIf="selectedAction">
      <mat-slide-toggle [checked]="selectedAction?.payload?.enforce_booking_source" [disabled]="true">
        Enforce booking source within links
      </mat-slide-toggle>

      <div>
        <mat-form-field appearance="outline" class="w-100" *ngIf="selectedAction?.payload?.booking_sources?.length > 0">
          <mat-label>Select booking source to get external link</mat-label>
          <mat-select (valueChange)="bookingSourceChanged($event)" [disabled]="!selectedEvent" [value]="undefined">
            <mat-option *ngFor="let bookingSource of selectedAction?.payload?.booking_sources!" [value]="bookingSource">
              {{ bookingSource }}</mat-option
            >
          </mat-select>

          <mat-hint *ngIf="bookingSourceLink">
            <span *ngIf="bookingSourceLink?.startsWith('https://'); else elseBlock"
              >External Link: <a target="_blank" [href]="bookingSourceLink">{{ bookingSourceLink }}</a></span
            >
            <ng-template #elseBlock>{{ bookingSourceLink }}</ng-template>
          </mat-hint>
        </mat-form-field>
        <div
          *ngIf="selectedAction?.payload?.booking_sources?.length === 0 || !selectedAction?.payload?.booking_sources"
          class="text-muted mb-1"
        >
          No booking sources defined...
        </div>
      </div>
    </div>
    <div *ngIf="!selectedAction">Please save first for additional information</div>
  </div>

  <div *ngSwitchCase="ActionType.formSubmission">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Form</mat-label>
      <mat-select (valueChange)="update($event)" [value]="selectedAction?.id">
        <mat-option *ngFor="let option of questionForms" [value]="option.id"> {{ option.title }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngSwitchCase="ActionType.registration">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Form</mat-label>
      <mat-select (valueChange)="update($event)" [value]="selectedAction?.id">
        <mat-option *ngFor="let option of registerForms" [value]="option.id"> {{ option.title }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngSwitchCase="ActionType.login" class="text-center">
    <p><strong>Login Widget</strong></p>
    <mat-form-field *ngIf="isLoginAction" appearance="outline" class="w-100">
      <mat-label>Default success login page</mat-label>
      <mat-select (selectionChange)="saveLoginAction()" [formControl]="successPageIdControl" [disabled]="!selectedAction && isLoginAction">
        <mat-option *ngFor="let page of pages" [value]="page.id">
          {{ page.title }}
        </mat-option>
      </mat-select>
      <mat-error><app-form-control-error [control]="successPageIdControl"></app-form-control-error></mat-error>
    </mat-form-field>
  </div>
</div>

<p class="text-muted mb-1">
  <small>{{ selectedAction?.id }}</small>
</p>
