import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter, SatCalendar, SatCalendarFooter, SatDatepicker } from 'saturn-datepicker';

@Component({
  selector: 'app-time-footer',
  templateUrl: './time-footer.component.html',
  styleUrls: ['./time-footer.component.scss']
})
export class TimeFooterComponent<T> implements SatCalendarFooter<Moment>, OnInit {
  public beginTimeString?: string;
  public endTimeString?: string;
  private destroyed = new Subject<void>();
  private readonly INPUT_FORMAT = 'HH:mm';

  public beginTimeChange(event: InputEvent): void {
    this.beginTimeString = (event.target as HTMLInputElement).value;
    this.setCalenderBeginTime(this.beginTimeString);

    // Needed to trigger time change (since on same day)
    this.calendar.beginDateSelectedChange.emit(this.calendar.beginDate?.clone().add(1, 'day'));
    this.calendar.dateRangesChange.emit({
      begin: this.calendar.beginDate?.clone().add(1, 'day')!,
      end: this.calendar.endDate
    });

    this.calendar.beginDateSelectedChange.emit(this.calendar.beginDate!);
    this.calendar.dateRangesChange.emit({
      begin: this.calendar.beginDate,
      end: this.calendar.endDate
    });
  }

  public endTimeChange(event: InputEvent): void {
    this.endTimeString = (event.target as HTMLInputElement).value;
    this.setCalenderEndTime(this.endTimeString);

    // Needed to trigger time change (since on same day)
    this.calendar.dateRangesChange.emit({
      begin: this.calendar.beginDate,
      end: this.calendar.endDate!.clone().add(1, 'day')
    });

    this.calendar.dateRangesChange.emit({
      begin: this.calendar.beginDate,
      end: this.calendar.endDate
    });
  }

  private setCalenderEndTime(timeString?: string): void {
    if (!timeString) {
      return;
    }
    const endTime = moment(timeString, this.INPUT_FORMAT);
    const currentEndDate = this.calendar.endDate?.clone();
    currentEndDate?.hours(endTime.hours());
    currentEndDate?.minutes(endTime.minutes());
    this.calendar.endDate = this.dateAdapter.deserialize(currentEndDate?.toDate());
  }

  private setCalenderBeginTime(timeString?: string): void {
    if (!timeString) {
      return;
    }
    const beginTime = moment(timeString, this.INPUT_FORMAT);
    const currentBeginDate = this.calendar.beginDate?.clone();
    currentBeginDate?.hours(beginTime.hours());
    currentBeginDate?.minutes(beginTime.minutes());
    this.calendar.beginDate = this.dateAdapter.deserialize(currentBeginDate?.toDate());
    this.calendar.activeDate = this.calendar.beginDate!;
  }

  public doneClicked(): void {
    this.datePicker.close();
  }

  public ngOnInit(): void {
    this.calendar.dateRangesChange.subscribe((range: { begin: Moment; end: Moment }) => {
      this.setCalenderBeginTime(this.beginTimeString);
      this.setCalenderEndTime(this.endTimeString);

      if (this.beginTimeString !== range.begin.format(this.INPUT_FORMAT) || this.endTimeString !== range.end.format(this.INPUT_FORMAT)) {
        // Needed to trigger time change (since on same day)
        this.calendar.dateRangesChange.emit({
          begin: this.calendar.beginDate,
          end: this.calendar.endDate!.clone().add(1, 'day')
        });

        this.calendar.dateRangesChange.emit({
          begin: this.calendar.beginDate,
          end: this.calendar.endDate
        });
      }
    });
    this.beginTimeString = this.calendar.beginDate?.format(this.INPUT_FORMAT);
    this.endTimeString = this.calendar.endDate?.format(this.INPUT_FORMAT);
  }

  constructor(
    public calendar: SatCalendar<Moment>,
    public datePicker: SatDatepicker<Moment>,
    private dateAdapter: DateAdapter<Moment>,
    cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => cdr.markForCheck());
  }
}
