<mat-form-field class="mb-2" [style.width]="fullWidth ? '100%' : 'inherit'">
  <mat-label>Language</mat-label>
  <mat-select (ngModelChange)="localeChanged($event)" [(ngModel)]="locale" [disabled]="!meta || disabled">
    <mat-option *ngFor="let language of meta?.language?.available" [value]="language">
              <span class="d-flex align-items-center justify-content-between">
                <span>{{getLanguageName(language)}} <span *ngIf="language == meta?.language?.default && !hideTranslationStatus">(Default)</span></span>
                <i *ngIf="!hideTranslationStatus" class="fas fa-circle text-success" [class.text-danger]="meta?.language?.set.indexOf(language) == -1"></i>
              </span>
    </mat-option>
  </mat-select>
</mat-form-field>
