import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventBooking } from 'src/app/entities/events/event-booking.entity';
import { Event } from 'src/app/entities/events/event.entity';
import { Guest } from 'src/app/entities/events/guest.entity';
import { Session } from 'src/app/entities/events/session.entity';
import { PaginatedResponse } from 'src/app/entities/paginated-response.entity';
import { TableSort } from 'src/app/entities/table-sort.entity';
import { SortsAdapter } from 'src/app/utils/sorts-adapter';
import { ApiService } from '../api.service';
import { HttpCacheService } from '../http-cache.service';
import { EventMeta } from '../../../entities/events/event-meta.entity';

@Injectable({
  providedIn: 'root'
})
export class EventsService extends ApiService {
  private endpoint = 'events';

  public get(projectId: number, eventId: number, locale: string = 'de'): Observable<Event> {
    return this.http
      .get<{ data: Event; meta: EventMeta }>(`projects/${projectId}/${this.endpoint}/${eventId}?locale=${locale}`)
      .pipe(map(res => new Event().deserialize({ ...res.data, meta: res.meta })));
  }

  public copy(projectId: number, eventId: number): Observable<Event> {
    return this.http
      .get<{ data: Event; meta: EventMeta }>(`projects/${projectId}/${this.endpoint}/${eventId}/copy`)
      .pipe(map(res => new Event().deserialize({ ...res.data, meta: res.meta })));
  }

  public getAll(
    projectId: number,
    perPage: number = 100,
    page: number = 1,
    search: string = '',
    sorts: TableSort[] = [],
    include: string = '',
    locale: string = 'de'
  ): Observable<PaginatedResponse<Event>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts),
      include: include
    };
    return this.http
      .get<PaginatedResponse<Event>>(`projects/${projectId}/${this.endpoint}?locale=${locale}`, {
        params
      })
      .pipe(
        map(res => ({
          ...res,
          data: res.data.map(event => new Event().deserialize(event))
        }))
      );
  }

  public create(projectId: number, event: Partial<Event>): Observable<Event> {
    return this.http
      .post<{ data: Event; meta: EventMeta }>(`projects/${projectId}/${this.endpoint}`, event.serialize!())
      .pipe(map(res => new Event().deserialize({ ...res.data, meta: res.meta })));
  }

  public delete(projectId: number, eventId: number): Observable<void> {
    return this.http.delete<undefined>(`projects/${projectId}/${this.endpoint}/${eventId}`);
  }

  public update(projectId: number, event: Event): Observable<Event> {
    return this.http
      .put<{ data: Event; meta: EventMeta }>(`projects/${projectId}/${this.endpoint}/${event.id}`, event.serialize!())
      .pipe(map(res => new Event().deserialize({ ...res.data, meta: res.meta })));
  }

  public upload(projectId: number, event: Event, data: FormData): Observable<{ files: string[] }> {
    return this.http.post<{ files: string[] }>(`projects/${projectId}/${this.endpoint}/${event.id}/upload`, data);
  }

  public getGuestsOfEvent(
    projectId: number,
    eventId: number,
    perPage: number = 20,
    page: number = 1,
    search: string = '',
    sorts: TableSort[] = []
  ): Observable<PaginatedResponse<Guest>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search,
      sort: SortsAdapter.sortsToString(sorts)
    };
    return this.http
      .get<PaginatedResponse<Guest>>(`projects/${projectId}/${this.endpoint}/${eventId}/guests`, { params })
      .pipe(
        map(res => ({
          ...res,
          data: res.data.map(guest => new Guest().deserialize(guest))
        }))
      );
  }

  public addGuestToEvent(projectId: number, eventId: number, guestId: number): Observable<Event> {
    return this.http.post<Event>(`projects/${projectId}/${this.endpoint}/${eventId}/guests/${guestId}`, {});
  }

  public removeGuestFromEvent(projectId: number, eventId: number, guestId: number) {
    return this.http.delete<undefined>(`projects/${projectId}/${this.endpoint}/${eventId}/guests/${guestId}`, {});
  }

  public updateBooking(projectId: number, booking: EventBooking) {
    return this.http.put<undefined>(`projects/${projectId}/${this.endpoint}/${booking.event_id}/bookings/${booking.uuid}`, {
      booking_details: booking.booking_details
    });
  }

  public getSessionsOfEvent(
    projectId: number,
    eventId: number,
    perPage: number = 20,
    page: number = 1,
    search: string = ''
  ): Observable<PaginatedResponse<Session>> {
    const params = {
      per_page: perPage.toString(),
      page: page.toString(),
      search
    };
    return this.http.get<PaginatedResponse<Session>>(`projects/${projectId}/${this.endpoint}/${eventId}/sessions`, { params });
  }

  // USE REST MULTISTATUS 207
  public createSessions(projectId: number, eventId: number, sessions: Partial<Session[]>): Observable<Session[]> {
    return this.http.post<Session[]>(`projects/${projectId}/${this.endpoint}/${eventId}/sessions`, sessions);
  }

  public createSession(projectId: number, eventId: number, session: Partial<Session>): Observable<Session> {
    return this.http.post<Session>(`projects/${projectId}/${this.endpoint}/${eventId}/sessions`, session);
  }

  public deleteSessions(projectId: number, eventId: number, sessionIds: number[]) {
    return this.http.delete<Session[]>(`projects/${projectId}/${this.endpoint}/${eventId}/sessions/${sessionIds.join()}`);
  }

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected httpCacheService: HttpCacheService,
    protected storage: LocalStorageService
  ) {
    super(http, router, httpCacheService, storage);
  }
}
