import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-sales-progress-bar',
  templateUrl: './sales-progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesProgressBarComponent {
  @Input() public percentageValue: number = 0;
  @Input() public baseValue: number = 0;
  @Input() public barValueTemplate: TemplateRef<any> | null = null;
}
