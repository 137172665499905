import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Moment } from 'moment';
import { TimeFooterComponent } from './time-footer/time-footer.component';
import { Required } from 'src/app/utils/required-input';

@Component({
  selector: 'app-date-time-range-picker',
  templateUrl: './date-time-range-picker.component.html',
  styleUrls: ['./date-time-range-picker.component.scss']
})
export class DateTimeRangePickerComponent {
  @Input() public placeholder?: string;
  @Input() public required?: boolean;
  @Input() public rangeMode?: boolean = true;
  @Input() public serverErrors?: string[];
  @Input() public filter?: (d: Moment | null) => boolean;
  @Input() @Required public label!: string;
  @Input() public hint?: string;
  @Output() @Required public beginChange = new EventEmitter<Moment | null>();
  @Output() @Required public endChange = new EventEmitter<Moment | null>();

  public timeFooter = TimeFooterComponent;
  public range: {
    begin: Moment | null;
    end: Moment | null;
  } = { begin: null, end: null };

  @Input()
  @Required
  public set begin(value: Moment) {
    this.range = { ...this.range, begin: value };
  }

  @Input()
  @Required
  public set end(value: Moment) {
    this.range = { ...this.range, end: value };
  }

  public rangeChange(event): void {
    this.range = event;
    if (!this.beginChange || !this.endChange) {
      return;
    }
    this.beginChange.emit(this.range.begin);
    this.endChange.emit(this.range.end);
  }
}
