/* eslint-disable @typescript-eslint/no-use-before-define */
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { Required } from '../utils/required-input';
import { FormErrorValue, FormServerErrors } from '../entities/unprocessable-entity-response';

@Directive({
  selector: '[appInputServerErrors]',
  providers: [{ provide: NG_VALIDATORS, useExisting: InputServerErrorDirective, multi: true }]
})
export class InputServerErrorDirective implements Validator {
  private control?: AbstractControl;

  private _inputServerErrors?: FormErrorValue;

  public get inputServerErrors(): FormErrorValue | undefined {
    return this._inputServerErrors;
  }

  @Input('appInputServerErrors')
  @Required
  public set inputServerErrors(value: FormErrorValue | undefined) {
    this._inputServerErrors = value;
    if (this.control) {
      this.control.markAsTouched();
      this.control.updateValueAndValidity();
    }
  }

  public validate(control: AbstractControl): { [key: string]: any } | null {
    this.control = control;
    return this.inputServerErrors ? this.serverErrorValidator(this.inputServerErrors)(control) : null;
  }

  private serverErrorValidator(inputServerErrors: FormErrorValue): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      control.markAsTouched();
      if (inputServerErrors.errors) {
        return { inputServerErrors: inputServerErrors.errors };
      } else {
        Object.keys(inputServerErrors).map((key, index) => {
          if (control.get(key)) {
            control.get(key)?.setErrors({ inputServerErrors: inputServerErrors[key].errors });
          }
        });
        return null;
      }
    };
  }
}
