import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {filter, map, pairwise, throttleTime} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-virtual-scroll-list',
    templateUrl: './virtual-scroll-list.component.html',
    styleUrls: ['./virtual-scroll-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualScrollListComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('scroller') scroller!: CdkVirtualScrollViewport;
    @Input('items') items$!: EventEmitter<any[]>;
    @Input('itemDisplayKey') itemDisplayKey!: string;
    @Output('scrollEnd') scrollEnd: EventEmitter<void> = new EventEmitter<void>();
    @Output('itemSelected') itemSelected: EventEmitter<any> = new EventEmitter<any>();

    public items: any[] = [];
    public selectedItem: any;

    private itemScanner?: Subscription;
    private scrollScanner?: Subscription;

    public ngOnInit(): void {
        this.items$.subscribe({
            next: value => {
                this.items = [...this.items, ...value];
                this.cdr.detectChanges();
            }
        })
    }

    public ngAfterViewInit() {
        this.scrollScanner = this.scroller.elementScrolled().pipe(
            map(() => this.scroller.measureScrollOffset('bottom')),
            pairwise(),
            filter(([y1, y2]) => (y2 < y1 && y2 < 140)),
            throttleTime(200)
        ).subscribe( {
            next: () => {
                this.scrollEnd.emit();
                this.cdr.markForCheck();
            }
        });
    }

    public selectItem(item: any) {
        this.selectedItem = item;
        this.itemSelected.emit(item);
        this.cdr.markForCheck();
    }

    public ngOnDestroy() {
        if(this.scrollScanner) {
            this.scrollScanner.unsubscribe();
        }

        if(this.itemScanner) {
            this.itemScanner.unsubscribe();
        }
    }

    constructor(
        private cdr: ChangeDetectorRef
    ) {}
}
