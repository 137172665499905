import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput', { static: true }) public searchInput;

  @Input() public withCustomLabel: boolean = false;
  @Output() public searchValue: EventEmitter<string> = new EventEmitter<string>();

  constructor(private route: ActivatedRoute) {}

  public ngOnInit() {}

  public ngAfterViewInit(): void {
    this.searchInput.nativeElement.value = this.route.snapshot.queryParamMap.get('search');

    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((e: any) => {
        this.searchValue.emit((e.target as HTMLInputElement).value);
      });
  }
}
